// ClipsPostsContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { collection, query, orderBy, startAfter, limit, getDocs, doc, updateDoc, deleteDoc, addDoc, getDoc, increment } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../utils/firebaseConfig';
import { getPostSummaries, deletePostFromDB, getPostImpressions } from '../utils/dbUtilities';

export const ClipsPostsContext = createContext();

export const ClipsPostsProvider = ({ children }) => {
    const [posts, setPosts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const { currentUser } = useAuth();

    const updateLocalImpressionCount = useCallback((postId) => {
        setPosts(currentPosts => 
            currentPosts.map(post => 
                post.id === postId 
                    ? { ...post, viewerImpressions: (post.viewerImpressions || 0) + 1 } 
                    : post
            )
        );
    }, []);

    const getReportedStatus = useCallback(async (postIds) => {
        const reportedStatuses = await Promise.all(
            postIds.map(async (postId) => {
                const postRef = doc(db, 'clipsPosts', postId);
                const postDoc = await getDoc(postRef);
                return postDoc.exists() ? postDoc.data().isReported : false;
            })
        );
        return reportedStatuses;
    }, []);

    const fetchInitialPosts = useCallback(async () => {
        setLoading(true);
        try {
            const initialQuery = query(collection(db, 'clipsPosts'), orderBy('timestamp', 'desc'), limit(50));
            const snapshot = await getDocs(initialQuery);
            const fetchedPosts = snapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    showInDiscoverBox: doc.data().showInDiscoverBox || false,
                    viewCount: doc.data().viewCount || 0,
                    linkClickCount: doc.data().linkClickCount || 0,
                    impressionCount: doc.data().impressionCount || 0,
                    discoveryBoxImpressionCount: doc.data().discoveryBoxImpressionCount || 0,
                    instagramClickCount: doc.data().instagramClickCount || 0,
                    twitterClickCount: doc.data().twitterClickCount || 0,
                    tiktokClickCount: doc.data().tiktokClickCount || 0,
                    youtubeClickCount: doc.data().youtubeClickCount || 0,
                    facebookClickCount: doc.data().facebookClickCount || 0
                }))
                .filter(post => !post.deleted);
    
            const postIds = fetchedPosts.map(post => post.id);
            const [summaries, viewerImpressions, reportedStatuses] = await Promise.all([
                getPostSummaries(postIds),
                Promise.all(postIds.map(id => getPostImpressions(id))),
                getReportedStatus(postIds)
            ]);
    
            const postsWithAllData = fetchedPosts.map((post, index) => ({
                ...post,
                summary: summaries[post.id] || post.description.substring(0, 100) + '...',
                viewerImpressions: viewerImpressions[index],
                isReported: reportedStatuses[index]
            }));
    
            setPosts(postsWithAllData);
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === 50);
        } catch (error) {
            console.error('Error fetching initial posts:', error);
            setError('Failed to fetch posts. Please try again.');
        }
        setLoading(false);
    }, [getReportedStatus]);

    useEffect(() => {
        fetchInitialPosts();
    }, [fetchInitialPosts]);

    const fetchMorePosts = async () => {
        if (loading || !hasMore || !lastVisible) return;
      
        setLoading(true);
        try {
            const nextQuery = query(collection(db, 'clipsPosts'), orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(50));
            const snapshot = await getDocs(nextQuery);
            const morePosts = snapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    showInDiscoverBox: doc.data().showInDiscoverBox || false,
                    viewCount: doc.data().viewCount || 0,
                    linkClickCount: doc.data().linkClickCount || 0,
                    impressionCount: doc.data().impressionCount || 0,
                    discoveryBoxImpressionCount: doc.data().discoveryBoxImpressionCount || 0,
                    instagramClickCount: doc.data().instagramClickCount || 0,
                    twitterClickCount: doc.data().twitterClickCount || 0,
                    tiktokClickCount: doc.data().tiktokClickCount || 0,
                    youtubeClickCount: doc.data().youtubeClickCount || 0,
                    facebookClickCount: doc.data().facebookClickCount || 0
                }))
                .filter(post => !post.deleted);
    
            const postIds = morePosts.map(post => post.id);
            const [viewerImpressions, reportedStatuses] = await Promise.all([
                Promise.all(postIds.map(id => getPostImpressions(id))),
                getReportedStatus(postIds)
            ]);
    
            const postsWithImpressions = morePosts.map((post, index) => ({
                ...post,
                viewerImpressions: viewerImpressions[index],
                isReported: reportedStatuses[index]
            }));
    
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === 50);
            setPosts(prevPosts => [...prevPosts, ...postsWithImpressions]);
        } catch (error) {
            console.error('Error fetching more posts:', error);
            setError('Failed to fetch more posts. Please try again.');
        }
        setLoading(false);
    };    

    const addNewPost = (newPost) => {
        setPosts(currentPosts => [{
          ...newPost,
          profileImageUrl: newPost.profileImageUrl,
          viewCount: 0,
          linkClickCount: 0,
          impressionCount: 0,
          discoveryBoxImpressionCount: 0,
          instagramClickCount: 0,
          twitterClickCount: 0,
          tiktokClickCount: 0,
          youtubeClickCount: 0,
          facebookClickCount: 0
        }, ...currentPosts]);
      };      

    const updateSinglePost = (updatedPost) => {
        setPosts((prevPosts) => {
            const postIndex = prevPosts.findIndex((post) => post.id === updatedPost.id);
            if (postIndex !== -1) {
                const updatedPosts = [...prevPosts];
                updatedPosts[postIndex] = {
                    ...updatedPost,
                    viewCount: updatedPost.viewCount || 0,
                    linkClickCount: updatedPost.linkClickCount || 0,
                    impressionCount: updatedPost.impressionCount || 0,
                    discoveryBoxImpressionCount: updatedPost.discoveryBoxImpressionCount || 0,
                    instagramClickCount: updatedPost.instagramClickCount || 0,
                    twitterClickCount: updatedPost.twitterClickCount || 0,
                    tiktokClickCount: updatedPost.tiktokClickCount || 0,
                    youtubeClickCount: updatedPost.youtubeClickCount || 0,
                    facebookClickCount: updatedPost.facebookClickCount || 0
                };
                return updatedPosts;
            }
            return prevPosts;
        });
    };

    const updateReportedStatus = async (postId, isReported) => {
        try {
            await updateDoc(doc(db, 'clipsPosts', postId), { isReported });
            setPosts(currentPosts =>
                currentPosts.map(post =>
                    post.id === postId ? { ...post, isReported } : post
                )
            );
        } catch (error) {
            console.error('Error updating reported status:', error);
        }
    };

    const deletePostById = async (postId) => {
        try {
            await deletePostFromDB('clipsPosts', postId);
            setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
        } catch (error) {
            console.error('Error deleting post:', error);
            throw error;
        }
    };        

    const addComment = async (postId, commentData) => {
        try {
            await addDoc(collection(db, 'clipsComments'), {
                postId,
                ...commentData,
            });
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };
  
    const deleteComment = async (commentId) => {
        try {
            await deleteDoc(doc(db, 'clipsComments', commentId));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const updatePostInDB = async (collectionName, postId, updatedData) => {
        try {
            const postRef = doc(db, collectionName, postId);
            const updatedPostWithTimestamp = {
                ...updatedData,
                lastEdited: new Date().toISOString()
            };
            await updateDoc(postRef, updatedPostWithTimestamp);
            
            const updatedDocSnap = await getDoc(postRef);
            if (updatedDocSnap.exists()) {
                const updatedPost = {
                    id: postId,
                    ...updatedDocSnap.data(),
                    viewCount: updatedDocSnap.data().viewCount || 0,
                    linkClickCount: updatedDocSnap.data().linkClickCount || 0,
                    impressionCount: updatedDocSnap.data().impressionCount || 0,
                    discoveryBoxImpressionCount: updatedDocSnap.data().discoveryBoxImpressionCount || 0,
                    instagramClickCount: updatedDocSnap.data().instagramClickCount || 0,
                    twitterClickCount: updatedDocSnap.data().twitterClickCount || 0,
                    tiktokClickCount: updatedDocSnap.data().tiktokClickCount || 0,
                    youtubeClickCount: updatedDocSnap.data().youtubeClickCount || 0,
                    facebookClickCount: updatedDocSnap.data().facebookClickCount || 0
                };
                updateSinglePost(updatedPost);
                return updatedPost;
            } else {
                throw new Error("Updated document not found");
            }
        } catch (error) {
            console.error('Error updating post:', error);
            throw error;
        }
    };
    
    return (
        <ClipsPostsContext.Provider value={{ 
            posts, 
            addNewPost, 
            updateSinglePost,
            updateReportedStatus, 
            deletePostById, 
            fetchMorePosts,
            loading,
            hasMore,
            error,
            addComment, 
            deleteComment, 
            updatePostInDB,
            updateLocalImpressionCount
        }}>
            {children}
        </ClipsPostsContext.Provider>
    );
};

export default ClipsPostsProvider;

