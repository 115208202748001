// ClipsPost.js

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FaRegPaperPlane,
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { CiViewList } from 'react-icons/ci';
import { useAuth } from '../contexts/AuthContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { timeAgo } from '../utils/timeFormatter';
import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import SharePost from './SharePost';
import { db } from '../utils/firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import CloseButton from './CloseButton';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';
import {
  incrementLinkClickCount,
  incrementInstagramClickCount,
  incrementTwitterClickCount,
  incrementTiktokClickCount,
  incrementYoutubeClickCount,
  incrementFacebookClickCount
} from '../utils/dbUtilities';

const TWO_MINUTES = 2 * 60 * 1000;

// --- Styled components for consistent layout ---
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  margin: '24px auto',
  width: '100%',
  maxWidth: '600px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  height: 'auto',
  minHeight: '300px',
  transition: 'max-width 0.3s ease-in-out, padding 0.3s ease-in-out'
}));

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  width: '100%'
});

// Post Title styling: 1.5rem
const StyledTitle = styled(Typography)({
  fontSize: '1.5rem', // ~24px
  fontWeight: 'bold',
  marginBottom: '8px',
  width: '100%',
  textAlign: 'left',
  cursor: 'pointer'
});

/**
 * Removes common URL prefixes (http://, https://, www.) and then limits to maxLength.
 */
const shortenUrl = (url) => {
  const urlWithoutPrefix = url.replace(/^https?:\/\/(www\.)?/i, '');
  const maxLength = 25;
  return urlWithoutPrefix.length > maxLength
    ? `${urlWithoutPrefix.slice(0, maxLength)}...`
    : urlWithoutPrefix;
};

const ClipsPost = ({
  id,
  title,
  author,
  description,
  city,
  state,
  country,
  location,
  categories,
  goals,
  imageUrl,
  timestamp,
  lastEdited,
  link,
  email,
  instagram,
  twitter,
  tiktok,
  youtube,
  facebook,
  isSmall,
  bio,
  profileImageUrl,
  userName
}) => {
  const navigate = useNavigate();
  const { currentUser, isLoggedIn, isAdmin } = useAuth();
  const { reportPost } = useContext(ClipsReportsContext);
  const { deletePostById } = useContext(ClipsPostsContext);

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [showReportForm, setShowReportForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [isAuthor, setIsAuthor] = useState(
    currentUser && currentUser.displayName === author
  );
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [chatWindowWidth, setChatWindowWidth] = useState(300);

  // Whether the full-screen profile image overlay is open
  const [isProfileImageExpanded, setIsProfileImageExpanded] = useState(false);

  // The post state for analytics
  const [post, setPost] = useState({
    isDeleted: false,
    viewCount: 0,
    linkClickCount: 0,
    impressionCount: 0,
    instagramClickCount: 0,
    twitterClickCount: 0,
    tiktokClickCount: 0,
    youtubeClickCount: 0,
    facebookClickCount: 0
  });

  // Check if user can see likes count
  const canSeeLikesCount = () => isAuthor || isAdmin;

  // Keep track of visited styling
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // Splits text by sentence for optional bullet points
  const splitText = (text) => {
    if (!text) return [];
    return text.split(/(?<=[.?!])\s+(?=[A-Z])/);
  };

  // === Real-time Firestore Likes ===
  useEffect(() => {
    const likesQueryRef = query(collection(db, 'clipsLikes'), where('postId', '==', id));
    const unsubscribe = onSnapshot(likesQueryRef, (snapshot) => {
      const likesArr = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLikeCount(likesArr.length);
      if (currentUser) {
        setIsLiked(likesArr.some((like) => like.userId === currentUser.uid));
        setIsAuthor(currentUser.displayName === author);
      }
    });
    return () => unsubscribe();
  }, [id, currentUser, author]);

  // === 2-Minute Polling for Post Analytics ===
  const fetchPostData = useCallback(async () => {
    if (!id) return;
    try {
      const docRef = doc(db, 'clipsPosts', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPost((prevPost) => ({
          ...prevPost,
          viewCount: data.viewCount || 0,
          linkClickCount: data.linkClickCount || 0,
          impressionCount: data.impressionCount || 0,
          instagramClickCount: data.instagramClickCount || 0,
          twitterClickCount: data.twitterClickCount || 0,
          tiktokClickCount: data.tiktokClickCount || 0,
          youtubeClickCount: data.youtubeClickCount || 0,
          facebookClickCount: data.facebookClickCount || 0
        }));
      }
    } catch (err) {
      console.error('Error fetching post data:', err);
    }
  }, [id]);

  useEffect(() => {
    // Initial fetch
    fetchPostData();
    // Then every 2 minutes
    const intervalId = setInterval(() => {
      fetchPostData();
    }, TWO_MINUTES);
    return () => clearInterval(intervalId);
  }, [fetchPostData]);

  // Sync localStorage "clickedPosts" for styling
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // === Like / Unlike Logic ===
  const handleLike = async (event) => {
    event.stopPropagation();
    if (!isLoggedIn || !currentUser || !id) {
      navigate('/login');
      return;
    }
    if (isLiked) {
      // Unlike
      const likeQueryRef = query(
        collection(db, 'clipsLikes'),
        where('postId', '==', id),
        where('userId', '==', currentUser.uid)
      );
      const snapshot = await getDocs(likeQueryRef);
      const likeDoc = snapshot.docs[0];
      if (likeDoc) {
        await deleteDoc(likeDoc.ref);
      }
    } else {
      // Like
      await addDoc(collection(db, 'clipsLikes'), {
        postId: id,
        userId: currentUser.uid,
        timestamp: new Date()
      });
    }
  };

  // === Navigation & Click Handlers ===
  const handleUsernameClick = (e) => {
    e.stopPropagation();
    navigate(`/clipsusersposts/${author}`);
  };
  const navigateToPostPage = () => {
    navigate(`/clipsposts/${id}`);
  };
  const handleTitleClick = (e) => {
    e.stopPropagation();
    const updatedClickedPosts = { ...clickedPosts, [id]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigateToPostPage();
  };

  // === Social link handlers ===
  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      link && link.includes('http') ? link : `https://${link}`,
      '_blank',
      'noopener,noreferrer'
    );
  };
  const handleInstagramClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (instagram) {
      window.open(
        `https://instagram.com/${instagram.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };
  const handleTwitterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (twitter) {
      window.open(
        `https://twitter.com/${twitter.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };
  const handleTiktokClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (tiktok) {
      window.open(
        `https://tiktok.com/@${tiktok.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };
  const handleYoutubeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (youtube) {
      window.open(
        `https://youtube.com/@${youtube.replace('@', '')}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };
  const handleFacebookClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (facebook) {
      window.open(
        `https://www.facebook.com/${facebook}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  // === Location-based navigation ===
  const navigateToCityPosts = (c) => {
    navigate(`/cityposts/${c}`);
  };
  const navigateToStatePosts = (s) => {
    navigate(`/stateposts/${s}`);
  };
  const navigateToCountryPosts = (ctr) => {
    navigate(`/countryposts/${ctr}`);
  };
  const navigateToLocationPosts = (loc) => {
    navigate(`/locationposts/${loc}`);
  };

  // === Reporting ===
  const toggleReportForm = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowReportForm(!showReportForm);
  };

  // === Contact List ===
  const toggleContactList = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowContactList(!showContactList);
  };
  const closeContactList = () => {
    setShowContactList(false);
  };

  // === Sharing ===
  const toggleSharePost = (e) => {
    e.stopPropagation();
    setShowSharePost((prev) => !prev);
  };
  const closeSharePost = () => {
    setShowSharePost(false);
  };

  // === Delete Post ===
  const handleRemove = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this post?')) {
      await deletePostById(id);
    }
  };

  // === Edit Post ===
  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/editclipspost/${id}`);
  };

  // === Chat ===
  const openChatWindow = (e) => {
    e.stopPropagation();
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowChatWindow(true);
  };

  // === Toggle profile image overlay ===
  const handleProfileImageClick = () => {
    setIsProfileImageExpanded(true);
  };

  // If post is flagged as deleted in Firestore, hide it
  if (post.isDeleted) return null;

  return (
    <FeedbackProvider>
      <VisibilityTracker
        postId={id}
        isDiscoveryBox={false}
        isPostAuthor={currentUser?.uid === post.authorId}
      >
        <StyledCard className={isSmall ? 'small-post' : ''}>
          {/* If there's a main image, render it */}
          {imageUrl && (
            <CardMedia
              component="img"
              image={imageUrl}
              alt={title}
              sx={{ cursor: 'pointer' }}
              onClick={handleTitleClick}
            />
          )}

          <StyledCardContent>
            {/* === Title and Like Row === */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <StyledTitle
                onClick={handleTitleClick}
                sx={{
                  color: clickedPosts[id] ? '#551A8B' : '#1a0dab',
                  marginBottom: 0
                }}
              >
                {title}
              </StyledTitle>

              {/* Like icon + optional count */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title={isLiked ? 'Unlike' : 'Like'}>
                  <IconButton onClick={handleLike}>
                    {isLiked ? (
                      <Favorite sx={{ color: '#ed4956' }} />
                    ) : (
                      <FavoriteBorder sx={{ color: '#262626' }} />
                    )}
                  </IconButton>
                </Tooltip>
                {canSeeLikesCount() && (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, textAlign: 'center', minWidth: '15px' }}
                  >
                    {likeCount}
                  </Typography>
                )}
              </Box>
            </Box>

            {/* === Categories (bubbles) === */}
            {categories && Array.isArray(categories) && categories.length > 0 && (
              <Box
                className="categories-container"
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  width: '100%'
                }}
              >
                {categories.map((category) => (
                  <span
                    key={category}
                    className="category-chip"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      navigate(`/categoryposts/${category}`);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f0',
                      padding: '5px 12px',
                      borderRadius: '16px',
                      fontSize: '14px',
                      color: '#333',
                      border: '1px solid #ddd',
                      fontWeight: 600
                    }}
                    onMouseOver={(ev) => {
                      ev.currentTarget.style.backgroundColor = '#e0e0e0';
                    }}
                    onMouseOut={(ev) => {
                      ev.currentTarget.style.backgroundColor = '#f0f0f0';
                    }}
                  >
                    {category}
                  </span>
                ))}
              </Box>
            )}

            <Box sx={{ mb: 1 }} />

            {/* === New sub-heading: "What's This About?" (left-aligned) === */}
            <Typography
              variant="h6"
              sx={{
                textAlign: 'left',
                fontWeight: 'bold',
                mt: 2,
                mb: 1,
                color: '#000'
              }}
            >
              What Are You Sharing?
            </Typography>

            {/* === Description === */}
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'pre-line',
                mt: 1,
                textAlign: 'left'
              }}
            >
              {splitText(description).length > 1 ? (
                <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  {splitText(description).map((sentence, idx) => (
                    <li key={idx}>{sentence.trim()}</li>
                  ))}
                </ul>
              ) : (
                description
              )}
            </Typography>

            {/* === Link (same font size as location) === */}
            {link && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'left',
                  marginTop: '15px',
                  marginBottom: '10px'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    color: '#1a0dab',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: '1rem'
                  }}
                  onClick={handleLinkClick}
                >
                  {shortenUrl(link)}
                </Typography>
              </div>
            )}

            {/* === Posted by === */}
            <Typography
              variant="body2"
              color="text.secondary"
              onClick={handleUsernameClick}
              sx={{ cursor: 'pointer', mb: lastEdited ? 0.5 : 2 }}
            >
              Posted {timeAgo(timestamp?.toDate ? timestamp.toDate() : timestamp)} by{' '}
              <span
                style={{
                  color: '#1a0dab',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
              >
                {author}
              </span>
            </Typography>

            {/* === Last Edited === */}
            {lastEdited && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                Last Edited{' '}
                {timeAgo(lastEdited?.toDate ? lastEdited.toDate() : lastEdited)}
              </Typography>
            )}

            {/* === Location info === */}
            {(city || state || country || location) && (
              <Typography
                variant="caption"
                sx={{
                  fontSize: '1rem',
                  mb: 2,
                  textAlign: 'left'
                }}
              >
                {city && (
                  <>
                    <span
                      className="location-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToCityPosts(city);
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none'
                      }}
                    >
                      {city}
                    </span>
                  </>
                )}
                {city && state ? ', ' : ''}
                {state && (
                  <>
                    <span
                      className="location-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToStatePosts(state);
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none'
                      }}
                    >
                      {state}
                    </span>
                  </>
                )}
                {(city || state) && country ? ', ' : ''}
                {country && (
                  <>
                    <span
                      className="location-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToCountryPosts(country);
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none'
                      }}
                    >
                      {country}
                    </span>
                  </>
                )}
                {location && (
                  <>
                    {' '}
                    (
                    <span
                      className="location-link"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToLocationPosts(location);
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none'
                      }}
                    >
                      {location}
                    </span>
                    )
                  </>
                )}
              </Typography>
            )}

            {/* === Impressions === */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mt: 2,
                mb: 2
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: '#000000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  lineHeight: 1.4,
                  fontWeight: 700
                }}
              >
                <span
                  style={{
                    backgroundColor: '#fff',
                    color: '#000',
                    fontSize: '15px',
                    padding: '3px 8px',
                    border: '2px solid #000',
                    borderRadius: '6px',
                    marginRight: '8px'
                  }}
                >
                  {post.impressionCount?.toLocaleString() || 0}
                </span>
                Total Views
              </Typography>
            </Box>

            {/* === "Who Posted This?" Heading & Profile Info === */}
            {(userName || bio || profileImageUrl) && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0px',
                  marginTop: '15px'
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mt: 2,
                    mb: 1,
                    color: '#000'
                  }}
                >
                  Who Are You?
                </Typography>

                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  {profileImageUrl && (
                    <div
                      style={{
                        cursor: 'pointer',
                        marginBottom: '3px',
                        width: isProfileImageExpanded ? '100%' : '200px',
                        height: isProfileImageExpanded ? 'auto' : '200px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        transition: 'all 0.3s ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      onClick={handleProfileImageClick}
                    >
                      <img
                        src={profileImageUrl}
                        alt="Profile"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          borderRadius: '50%',
                          transition: 'all 0.3s ease-in-out'
                        }}
                      />
                      {isProfileImageExpanded && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            zIndex: 10
                          }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            setIsProfileImageExpanded(false);
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                              fontWeight: 'bold',
                              fontSize: '20px'
                            }}
                          >
                            ×
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {userName && (
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mt: profileImageUrl ? 1 : 0,
                        mb: 0,
                        width: '100%',
                        lineHeight: 1
                      }}
                    >
                      {userName}
                    </Typography>
                  )}
                </div>

                {bio && (
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: 'white',
                      padding: '5px'
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontSize: '15px',
                        lineHeight: 1.4,
                        color: '#000000',
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        marginBottom: '0'
                      }}
                    >
                      {splitText(bio).length > 1 ? (
                        <ul style={{ paddingLeft: '20px', margin: 0 }}>
                          {splitText(bio).map((sentence, index) => (
                            <li key={index} style={{ marginBottom: '3px' }}>
                              {sentence.trim()}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        bio
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            )}

            {/* === "What's The Goal?" Moved Above Feedback === */}
            {goals && Array.isArray(goals) && goals.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mt: 2,
                    mb: 1,
                    color: '#000',
                    width: '100%'
                  }}
                >
                  What's The Goal?
                </Typography>
                <div
                  className="goals-container"
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '10px',
                    width: '100%'
                  }}
                >
                  {goals.map((goal) => (
                    <span
                      key={goal}
                      className="goal-chip"
                      style={{
                        cursor: 'default',
                        backgroundColor: '#e0f4ff',
                        padding: '5px 12px',
                        borderRadius: '16px',
                        fontSize: '14px',
                        color: '#333',
                        border: '1px solid #99e0ff',
                        fontWeight: 600
                      }}
                    >
                      {goal}
                    </span>
                  ))}
                </div>
              </>
            )}

            {/* === Feedback Section === */}
            <div
              style={{
                marginTop: '20px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {isLoggedIn ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      mt: 2,
                      mb: 1,
                      color: '#000'
                    }}
                  >
                    Give Feedback
                  </Typography>
                  <div style={{ marginTop: '10px' }}>
                    <Feedback postId={id} />
                  </div>
                </>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: 'center', fontSize: '1.1rem', py: 1 }}
                >
                  <span
                    onClick={() => navigate('/login')}
                    style={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '1rem'
                    }}
                  >
                    Login
                  </span>{' '}
                  or{' '}
                  <span
                    onClick={() => navigate('/signup')}
                    style={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      fontSize: '1rem'
                    }}
                  >
                    Sign Up
                  </span>{' '}
                  to View &amp; Provide Feedback
                </Typography>
              )}
            </div>

            {/* === Follow the Growth (Social) === */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
                width: '100%'
              }}
            >
              {(instagram || twitter || tiktok || youtube || facebook) && (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mt: 2,
                    mb: 2,
                    color: '#000'
                  }}
                >
                  Follow the Growth
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '10px'
                }}
              >
                {instagram && (
                  <FaInstagram
                    className="social-icon instagram"
                    onClick={handleInstagramClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {twitter && (
                  <FaTwitter
                    className="social-icon twitter"
                    onClick={handleTwitterClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {tiktok && (
                  <FaTiktok
                    className="social-icon tiktok"
                    onClick={handleTiktokClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {youtube && (
                  <FaYoutube
                    className="social-icon youtube"
                    onClick={handleYoutubeClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {facebook && (
                  <FaFacebook
                    className="social-icon facebook"
                    onClick={handleFacebookClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
              </div>

              {/* Contact List */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  // ADJUSTED SPACING:
                  // Increase marginTop to match the spacing above & below
                  marginTop: '10px',
                  gap: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <Tooltip title="Join Contact List">
                    <IconButton
                      onClick={toggleContactList}
                      style={{ padding: '4px', color: '#1a0dab', fontSize: '1rem' }}
                    >
                      <GoAlert />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    onClick={toggleContactList}
                    style={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '1rem'
                    }}
                  >
                    Email Updates Sign Up
                  </Typography>
                </div>
                {isAuthor && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <Tooltip title="View Email Sign Ups">
                      <IconButton
                        onClick={() => navigate('/view-contact-list')}
                        style={{ padding: '4px', color: '#1a0dab' }}
                      >
                        <CiViewList style={{ fontSize: '24px' }} />
                      </IconButton>
                    </Tooltip>
                    <Typography
                      variant="body2"
                      onClick={() => navigate('/view-contact-list')}
                      style={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                      }}
                    >
                      View Email Sign Ups
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            {/* Share Post Section */}
            <Box
              sx={{
                marginTop: '10px',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {!showSharePost ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px'
                  }}
                >
                  <Tooltip title="Share this Post">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSharePost(true);
                      }}
                      style={{ padding: '4px', color: '#1a0dab', fontSize: '1rem' }}
                    >
                      <BsMegaphone style={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSharePost(true);
                    }}
                    style={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '1rem'
                    }}
                  >
                    Share this Post
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    padding: '15px 20px',
                    paddingTop: '50px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9'
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSharePost(false);
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      fontSize: '24px',
                      cursor: 'pointer',
                      padding: '0',
                      color: '#666',
                      position: 'absolute',
                      top: '10px',
                      left: '10px',
                      fontWeight: 'bold'
                    }}
                    aria-label="Close Share Options"
                  >
                    ×
                  </button>
                  <SharePost url={`${window.location.origin}/clipsposts/${id}`} />
                </Box>
              )}
            </Box>

            {/* Email block (below Share Post) */}
            {email && (
              <div
                style={{
                  width: 'fit-content',
                  textAlign: 'center',
                  margin: '0 auto',
                  marginBottom: '20px'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: '#1a0dab',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: '1rem'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`mailto:${email}`, '_blank', 'noopener,noreferrer');
                  }}
                >
                  {email}
                </Typography>
              </div>
            )}

            {/* Analytics for Author */}
            {isAuthor && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px 16px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  marginTop: '20px',
                  gap: '10px',
                  width: '100%',
                  backgroundColor: '#f8f9fa'
                }}
              >
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ mb: 1, fontWeight: 600 }}
                >
                  Your Post Analytics
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Post Page Views: {post.viewCount || 0}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  URL Link Clicks: {post.linkClickCount || 0}
                </Typography>
                <Typography variant="body2" sx={{ color: '#000' }}>
                  Post Impressions (Views in Feeds & Discovery Boxes):{' '}
                  {post.impressionCount || 0}
                </Typography>
                {instagram && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Instagram Clicks: {post.instagramClickCount || 0}
                  </Typography>
                )}
                {twitter && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Twitter Clicks: {post.twitterClickCount || 0}
                  </Typography>
                )}
                {tiktok && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    TikTok Clicks: {post.tiktokClickCount || 0}
                  </Typography>
                )}
                {youtube && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    YouTube Clicks: {post.youtubeClickCount || 0}
                  </Typography>
                )}
                {facebook && (
                  <Typography variant="body2" sx={{ color: '#000' }}>
                    Facebook Clicks: {post.facebookClickCount || 0}
                  </Typography>
                )}
              </div>
            )}
          </StyledCardContent>

          {/* Bottom bar: Edit/Delete or Report/Message */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            {isAuthor ? (
              <>
                <Tooltip title="Edit">
                  <IconButton onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={handleRemove}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Report">
                  <IconButton onClick={toggleReportForm}>
                    <OutlinedFlagIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send Message">
                  <IconButton onClick={openChatWindow}>
                    <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>

          {/* Report Form Modal */}
          {showReportForm && (
            <div onClick={(e) => e.stopPropagation()}>
              <ClipsReportForm
                postId={id}
                submitReport={reportPost}
                closeModal={() => setShowReportForm(false)}
              />
            </div>
          )}

          {/* Chat Window */}
          {showChatWindow && (
            <ChatWindow
              initialRecipient={author}
              onClose={() => setShowChatWindow(false)}
              width={chatWindowWidth}
              setWidth={setChatWindowWidth}
            />
          )}

          {/* Contact List Modal */}
          {showContactList && (
            <ContactList postId={id} closeModal={closeContactList} />
          )}

          {/* Fullscreen Profile Overlay */}
          {isProfileImageExpanded && profileImageUrl && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                bgcolor: 'rgba(0,0,0,0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
              }}
              onClick={() => setIsProfileImageExpanded(false)}
            >
              <Box
                sx={{
                  position: 'relative',
                  maxWidth: '80%',
                  maxHeight: '80%'
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <CloseButton
                  onClick={() => setIsProfileImageExpanded(false)}
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    zIndex: 1001,
                    fontSize: '24px'
                  }}
                />
                <img
                  src={profileImageUrl}
                  alt="Profile Expanded"
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    borderRadius: '8px'
                  }}
                />
              </Box>
            </Box>
          )}
        </StyledCard>
      </VisibilityTracker>
    </FeedbackProvider>
  );
};

export default ClipsPost;
