// RandomPost.js

import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, IconButton, List, ListItem, Paper, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { timeAgo } from '../utils/timeFormatter';
import VisibilityTracker from './VisibilityTracker';
import './RandomPost.css'; // <-- Import the stylesheet that has .posts

const RandomPost = () => {
  const { posts } = useContext(ClipsPostsContext);
  const navigate = useNavigate();
  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // Keep local clickedPosts in sync with localStorage
  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      if (savedPosts) setClickedPosts(JSON.parse(savedPosts));
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // Handle click on a post (for styling + navigation)
  const handlePostClick = useCallback(
    (postId) => {
      const updatedClickedPosts = { ...clickedPosts, [postId]: true };
      setClickedPosts(updatedClickedPosts);
      localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
      navigate(`/clipsposts/${postId}`);
    },
    [clickedPosts, navigate]
  );

  // Refresh for discover posts
  const handleRefresh = useCallback(() => {
    setIsInitialLoad(false);
    setRandomPostsKey((prevKey) => prevKey + 1);
  }, []);

  // Compute uniquePosts and randomize them
  const uniquePosts = useMemo(() => {
    if (!posts) return [];
    return Array.from(new Map(posts.map((post) => [post.id, post])).values());
  }, [posts]);

  const randomPosts = useMemo(() => {
    if (!uniquePosts.length) return [];
    if (isInitialLoad) {
      return uniquePosts.filter((post) => !post.deleted && !post.isReported).slice(0, 5);
    } else {
      const shuffledPosts = [...uniquePosts]
        .filter((post) => !post.deleted && !post.isReported)
        .sort(() => Math.random() - 0.5);
      return shuffledPosts.slice(0, Math.min(5, shuffledPosts.length));
    }
  }, [uniquePosts, randomPostsKey, isInitialLoad]);

  const getLocationString = useCallback((post) => {
    if (post.city && post.state) return `(${post.city}, ${post.state})`;
    if (post.city) return `(${post.city})`;
    if (post.state) return `(${post.state})`;
    return '';
  }, []);

  return (
    <div className="posts">
      <Fade in={true} timeout={800} unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mt: 4,            // Similar to Trending.js
            width: '100%',    // Similar to Trending.js
            maxWidth: '100%', // Similar to Trending.js
            mb: 2,            // Similar to Trending.js
            borderRadius: '16px',
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: '100%', mb: 2, textAlign: 'left' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2 }}
              >
                Discover Posts
              </Typography>
              <IconButton
                onClick={handleRefresh}
                sx={{
                  color: '#007aff',
                  padding: '8px',
                  backgroundColor: '#f5f5f7',
                  '&:hover': { backgroundColor: '#e8e8ed' },
                  transition: 'background-color 0.3s ease',
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          </Box>

          {randomPosts.length === 0 ? (
            <Typography variant="body1" sx={{ color: '#86868b' }}>
              No posts available.
            </Typography>
          ) : (
            <List sx={{ width: '100%', padding: 0 }}>
              {randomPosts.map((post, index) => (
                <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
                  <Fade in={true} timeout={500 + index * 100} unmountOnExit>
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px 0',
                        borderRadius: '8px',
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body1"
                            component="span"
                            onClick={() => handlePostClick(post.id)}
                            sx={{
                              color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                              textDecoration: 'none',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              '&:hover': { textDecoration: 'underline' },
                              fontSize: '1.1rem',
                              marginBottom: '4px',
                            }}
                          >
                            {post.title}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{
                              marginLeft: '8px',
                              color: '#555',
                              fontSize: '0.85rem',
                              fontWeight: 400,
                            }}
                          >
                            {post.timestamp?.toDate ? timeAgo(post.timestamp.toDate()) : 'No timestamp found'}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ color: '#86868b', fontSize: '0.9rem' }}
                        >
                          {getLocationString(post)}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
                      >
                        {post.summary ||
                          (post.description?.length > 100
                            ? `${post.description.substring(0, 100)}...`
                            : post.description)}
                      </Typography>
                    </ListItem>
                  </Fade>
                </VisibilityTracker>
              ))}
            </List>
          )}
        </Paper>
      </Fade>
    </div>
  );
};

export default RandomPost;
