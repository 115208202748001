// Bio.js

import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Typography, List, ListItem, Paper, Fade, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import './Bio.css';

const Bio = () => {
  const { posts } = useContext(ClipsPostsContext);
  const [userBios, setUserBios] = useState({});
  const navigate = useNavigate();
  const [clickedUserNames, setClickedUserNames] = useState(() => {
    const saved = localStorage.getItem('clickedUserNames');
    return saved ? JSON.parse(saved) : {};
  });
  const [randomBiosKey, setRandomBiosKey] = useState(0);

  useEffect(() => {
    aggregateRecentUserBios(posts);
  }, [posts]);

  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedUserNames');
      if (saved) {
        setClickedUserNames(JSON.parse(saved));
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  const aggregateRecentUserBios = (allPosts) => {
    const bios = {};
    allPosts
      .filter(
        (post) =>
          post.author &&
          post.bio &&
          post.bio.trim() !== '' &&
          !post.deleted &&
          !post.isReported
      )
      .sort((a, b) => {
        const timestampA = b.timestamp?.toDate ? b.timestamp.toDate() : new Date(b.timestamp);
        const timestampB = a.timestamp?.toDate ? a.timestamp.toDate() : new Date(a.timestamp);
        // descending by date
        return timestampA - timestampB;
      })
      .forEach((post) => {
        if (!bios[post.author]) {
          bios[post.author] = {
            bio: post.bio,
            city: post.city || '',
            state: post.state || '',
            name: post.userName || '',
          };
        }
      });
    setUserBios(bios);
  };

  const truncateBio = (bio) => {
    const words = bio.split(' ');
    if (words.length > 8) {
      return words.slice(0, 8).join(' ') + '...';
    }
    return bio;
  };

  const getLocationString = (city, state) => {
    if (city && state) return `${city}, ${state}`;
    if (city) return city;
    if (state) return state;
    return '';
  };

  const handleUserNameClick = (userName) => {
    const updatedClickedUserNames = { ...clickedUserNames, [userName]: true };
    setClickedUserNames(updatedClickedUserNames);
    localStorage.setItem('clickedUserNames', JSON.stringify(updatedClickedUserNames));
    navigate(`/clipsusersposts/${userName}`);
  };

  const handleRefresh = () => {
    setRandomBiosKey((prevKey) => prevKey + 1);
  };

  const randomUserBios = useMemo(() => {
    const entries = Object.entries(userBios).filter(
      ([author]) => !posts.find((p) => p.author === author && p.isReported)
    );
    // Shuffle
    for (let i = entries.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [entries[i], entries[j]] = [entries[j], entries[i]];
    }
    // Slice to top 8
    return entries.slice(0, Math.min(8, entries.length));
  }, [userBios, randomBiosKey, posts]);

  return (
    <div className="posts"> 
      {/* The same .posts container used in Trending.js */}
      <Fade in={true} timeout={800}>
        <Paper
          elevation={3}
          className="bio-container"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            mt: 4,
            width: '100%',
            maxWidth: '100%',
            mb: 2,
            borderRadius: '16px',
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              mb: 4,
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
              Discover People
            </Typography>
            <IconButton
              onClick={handleRefresh}
              sx={{
                color: '#007aff',
                backgroundColor: '#f5f5f7',
                '&:hover': { backgroundColor: '#e8e8ed' },
                transition: 'background-color 0.3s ease',
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>

          {Object.keys(userBios).length === 0 ? (
            <Typography variant="body1" sx={{ color: '#86868b' }}>
              No user profiles available.
            </Typography>
          ) : (
            <List sx={{ width: '100%', padding: 0 }}>
              {randomUserBios.map(([author, { bio, city, state, name }], index) => (
                <Fade in={true} timeout={500 + index * 100} key={`${author}-${index}`}>
                  <ListItem
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '16px 0',
                      borderRadius: '8px',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                      <Typography
                        variant="body1"
                        component="span"
                        onClick={() => handleUserNameClick(author)}
                        sx={{
                          color: clickedUserNames[author] ? '#551A8B' : '#1a0dab',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                          '&:hover': { textDecoration: 'underline' },
                          fontSize: '1.1rem',
                          marginBottom: '4px',
                        }}
                      >
                        {name || author}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: '#86868b', fontSize: '0.9rem' }}
                      >
                        {getLocationString(city, state)}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
                    >
                      {truncateBio(bio)}
                    </Typography>
                  </ListItem>
                </Fade>
              ))}
            </List>
          )}
        </Paper>
      </Fade>
    </div>
  );
};

export default Bio;
