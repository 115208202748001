// GlobalStyles.js

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;700&display=swap');

  body, .App, #root, * {
    font-family: 'Google Sans', sans-serif !important;
  }
`;
