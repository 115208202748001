// dbUtilities.js

import { db, storage } from './firebaseConfig';
import { collection, doc, setDoc, getDoc, getDocs, query, where, addDoc, deleteDoc, updateDoc, increment, deleteField, orderBy, limit, startAfter, serverTimestamp, documentId, writeBatch } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { onSnapshot, getFirestore } from 'firebase/firestore';
import axios from 'axios';

// Function to save data to Firestore
export const saveDataToDB = async (collectionName, data, id = null) => {
  try {
    const docRef = id ? doc(db, collectionName, id) : doc(collection(db, collectionName));
    await setDoc(docRef, data, { merge: true });
    return docRef.id;
  } catch (error) {
    console.error("Error writing to Firestore", error);
    throw new Error("Error writing to Firestore");
  }
};

// Function to get all data from a collection in Firestore
export const getAllDataFromDB = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const dataList = [];
    querySnapshot.forEach((doc) => dataList.push({ id: doc.id, ...doc.data() }));
    return dataList;
  } catch (error) {
    console.error("Error reading from Firestore", error);
    throw new Error("Error reading from Firestore");
  }
};

// Function to get a user by username
export const getUserByUsername = async (username) => {
  if (!username) {
    throw new Error("Invalid or missing username");
  }
  try {
    const q = query(collection(db, 'users'), where('username', '==', username.toLowerCase()));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No matching user found. Username is available.");
      return null; // Username is available
    }
    let user = null;
    querySnapshot.forEach((doc) => {
      // assuming only one user will have this username
      user = { id: doc.id, ...doc.data() };
    });
    console.log(`getUserByUsername: User found for ${username}`, user); // Log user data
    return user;
  } catch (error) {
    console.error("Error reading from Firestore", error);
    throw error; // Re-throwing the error for unexpected issues
  }
};

// Function to like a post
export const likePost = async (userId, postId, collectionName = 'likes') => {
  const timestamp = new Date();
  const likeData = { userId, postId, timestamp };
  const docRef = await addDoc(collection(db, collectionName), likeData);
  return docRef.id;
};

// Function to unlike a post
export const unlikePost = async (likeId, collectionName = 'likes') => {
  try {
    await deleteDoc(doc(db, collectionName, likeId));
  } catch (error) {
    console.error("Error unliking post", error);
    throw new Error("Error unliking post");
  }
};

// Function to get likes for a post
export const getLikesForPost = async (postId, collectionName = 'likes') => {
  try {
    const q = query(collection(db, collectionName), where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const likes = [];
    querySnapshot.forEach((doc) => {
      likes.push({ id: doc.id, ...doc.data() });
    });
    return likes;
  } catch (error) {
    console.error("Error fetching likes for post", error);
    throw new Error("Error fetching likes for post");
  }
};

// Function to get likes by a user
export const getUserLikes = async (userId, collectionName = 'likes') => {
  try {
    const q = query(collection(db, collectionName), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const likes = [];
    querySnapshot.forEach((doc) => {
      likes.push({ id: doc.id, ...doc.data() });
    });
    return likes;
  } catch (error) {
    console.error("Error fetching user likes", error);
    throw new Error("Error fetching user likes");
  }
};

// Function to generate a conversation ID
export const generateConversationId = (user1, user2) => {
  if (typeof user1 !== 'string' || typeof user2 !== 'string') {
    console.error('Invalid arguments for generateConversationId:', user1, user2);
    return null;
  }
  const conversationId = [user1, user2].sort().join('_');
  console.log(`Generated conversation ID: ${conversationId} for users ${user1} and ${user2}`);
  return conversationId;
};

// Function to block a user
export const blockUserInDB = async (blockingUser, blockedUser, blockTimestamp) => {
  try {
    const userRef = doc(db, 'users', blockingUser);
    const userData = (await getDoc(userRef)).data();
    if (!userData) {
      throw new Error("Blocking user not found");
    }
    userData.blockedUsers = { ...userData.blockedUsers, [blockedUser]: blockTimestamp };
    await setDoc(userRef, userData);
  } catch (error) {
    console.error("Error blocking user", error);
    throw new Error("Error blocking user");
  }
};

// Function to unblock a user
export const unblockUserInDB = async (unblockingUser, blockedUser) => {
  try {
    const userRef = doc(db, 'users', unblockingUser);
    const userData = (await getDoc(userRef)).data();
    if (!userData) {
      throw new Error("Unblocking user not found");
    }
    delete userData.blockedUsers[blockedUser];
    await setDoc(userRef, userData);
  } catch (error) {
    console.error("Error unblocking user", error);
    throw new Error("Error unblocking user");
  }
};

// Function to send a message
export const sendMessageToDb = async (senderUsername, receiverUsername, messageData) => {
  console.log('Received message data:', messageData); // Add this line
  const conversationId = generateConversationId(senderUsername, receiverUsername);
  const message = {
    ...messageData,
    sender: senderUsername,
    receiver: receiverUsername,
    conversationId,
    timestamp: messageData.timestamp || new Date().toISOString(), // Ensure timestamp is always set
    read: false,
  };

  console.log("Preparing to send message to Firestore:", {
    sender: senderUsername, 
    receiver: receiverUsername, 
    messageDetails: message
  });

  try {
    const docRef = await addDoc(collection(db, 'messages'), message);
    console.log("Message sent successfully to Firestore. Message ID:", docRef.id);
    return docRef.id; // Return the ID of the newly created document
  } catch (error) {
    console.error("Error sending message to Firestore:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

// Updated to handle initial message inclusion
export const getMessagesByConversationId = (conversationId, updateMessages, onError) => {
  console.log(`Setting up listener for conversationId: ${conversationId}`);
  const q = query(collection(db, 'messages'), where('conversationId', '==', conversationId), orderBy('timestamp', 'asc'));
  
  return onSnapshot(q, querySnapshot => {
    console.log(`Received snapshot for conversationId: ${conversationId}`);
    const messages = [];
    querySnapshot.forEach(doc => {
      console.log(`Message received: ${doc.id}`, doc.data());
      messages.push({ id: doc.id, ...doc.data() });
    });
    updateMessages(messages); // Update state with new messages
  }, (error) => {
    console.error(`Error fetching messages for conversationId: ${conversationId}`, error);
    if (onError) onError(error);
  });
};

// Function to get chat partners
export const getChatPartners = async (currentUsername) => {
  try {
    const sentMessagesQuery = query(collection(db, 'messages'), where('sender', '==', currentUsername));
    const receivedMessagesQuery = query(collection(db, 'messages'), where('receiver', '==', currentUsername));
    
    const [sentMessagesSnapshot, receivedMessagesSnapshot] = await Promise.all([
      getDocs(sentMessagesQuery),
      getDocs(receivedMessagesQuery)
    ]);

    const chatPartners = new Set();
    sentMessagesSnapshot.forEach((doc) => {
      const message = doc.data();
      chatPartners.add(message.receiver);
    });
    receivedMessagesSnapshot.forEach((doc) => {
      const message = doc.data();
      chatPartners.add(message.sender);
    });

    return Array.from(chatPartners);
  } catch (error) {
    console.error("Error fetching chat partners", error);
    throw new Error("Error fetching chat partners");
  }
};

// Function to add a report to the reports collection
export const addReportToDB = async (reportData) => {
  try {
    const reportId = await saveDataToDB('reports', reportData);
    return reportId;
  } catch (error) {
    console.error("Error adding report", error);
    throw new Error("Error adding report");
  }
};

// Function to get all reports from the reports collection
export const getAllReportsFromDB = async (collectionName) => {
  try {
    const reportsRef = collection(db, collectionName); // Use the provided collection name
    const snapshot = await getDocs(reportsRef);
    const reports = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return reports;
  } catch (error) {
    console.error('Error fetching reports from Firestore:', error);
    return [];
  }
};

// Function to get reports by postId
export const getReportsByPostId = async (postId) => {
  try {
    const q = query(collection(db, 'reports'), where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const reports = [];
    querySnapshot.forEach((doc) => {
      reports.push({ id: doc.id, ...doc.data() });
    });
    return reports;
  } catch (error) {
    console.error("Error fetching reports for post", error);
    throw new Error("Error fetching reports for post");
  }
};

// Function to report a user (HyroVault)
export const reportUser = async (userId, reportDetails) => {
  try {
    const fullReportDetails = {
      ...reportDetails,
      userId, // The ID of the user being reported
      type: 'user', // Adding a type property to distinguish user reports
      timestamp: new Date() // Timestamp of the report
    };
    await saveDataToDB('reports', fullReportDetails);
  } catch (error) {
    console.error("Error adding user report", error);
    throw new Error("Error adding user report");
  }
};

// Function to report a post (HyroVault)
export const reportPost = async (reportDetails) => {
  try {
    // Ensure the timestamp is formatted correctly
    const formattedReportDetails = {
      ...reportDetails,
      timestamp: new Date().toISOString(), // Store timestamp in ISO 8601 format
    };
    const reportId = await saveDataToDB('reports', formattedReportDetails);
    console.log("Report successfully saved with ID:", reportId);
    return reportId;
  } catch (error) {
    console.error("Error reporting post", error);
    throw new Error("Error reporting post");
  }
};

// Function to report a user (HyroClips)
export const reportClipUser = async (userId, reportDetails) => {
  try {
    const fullReportDetails = {
      ...reportDetails,
      userId, // The ID of the user being reported
      type: 'user', // Adding a type property to distinguish user reports
      timestamp: new Date(), // Timestamp of the report
      collection: 'clipsReports' // Specify the HyroClips collection
    };
    await saveDataToDB('clipsReports', fullReportDetails);
  } catch (error) {
    console.error("Error adding user report to clipsReports", error);
    throw new Error("Error adding user report to clipsReports");
  }
};

// Function to report a post (HyroClips)
export const reportClipPost = async (reportDetails) => {
  try {
    // Ensure the timestamp is formatted correctly
    const formattedReportDetails = {
      ...reportDetails,
      timestamp: new Date().toISOString(), // Store timestamp in ISO 8601 format
      collection: 'clipsReports' // Specify the HyroClips collection
    };
    const reportId = await saveDataToDB('clipsReports', formattedReportDetails);
    console.log("Clips report successfully saved with ID:", reportId);
    return reportId;
  } catch (error) {
    console.error("Error reporting post to clipsReports", error);
    throw new Error("Error reporting post to clipsReports");
  }
};

// Function to delete a user from the database
export const deleteUserFromDB = async (userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
  } catch (error) {
    console.error("Error deleting user from Firestore", error);
    throw new Error("Error deleting user from Firestore");
  }
};

// Function to delete a post from the database
export const deletePostFromDB = async (collectionName, docId) => {
  try {
    // Start a batch operation
    const batch = writeBatch(db);

    // Get the post reference and data
    const postRef = doc(db, collectionName, docId);
    const postDoc = await getDoc(postRef);

    if (!postDoc.exists()) {
      throw new Error(`Document with ID ${docId} not found`);
    }

    const postData = postDoc.data();

    // Set the deleted flag instead of deleting the document
    batch.update(postRef, {
      deleted: true,
      deletedAt: new Date().toISOString()
    });

    // Delete the post summary from discovery boxes
    const summaryRef = doc(db, 'postSummaries', docId);
    batch.delete(summaryRef);

    // Check if author has other posts - Modified query
    if (postData.author) {
      const otherPostsQuery = query(
        collection(db, collectionName),
        where('author', '==', postData.author),
        where('deleted', '!=', true)
      );
      const otherPostsSnapshot = await getDocs(otherPostsQuery);

      // If this is the author's last active post, delete their bio summary
      if (otherPostsSnapshot.empty) {
        const bioSummaryRef = doc(db, 'bioSummaries', postData.author);
        batch.delete(bioSummaryRef);
      }
    }

    // Delete any associated analytics data
    if (collectionName === 'clipsPosts') {
      // Reset all analytics counters
      batch.update(postRef, {
        viewCount: 0,
        linkClickCount: 0,
        impressionCount: 0,
        instagramClickCount: 0,
        twitterClickCount: 0,
        tiktokClickCount: 0,
        youtubeClickCount: 0,
        facebookClickCount: 0,
        discoveryBoxImpressionCount: 0
      });
    }

    // Commit all the batch operations
    await batch.commit();
    console.log(`Successfully marked post ${docId} as deleted and removed related data`);

    // Delete associated media files if they exist
    if (postData.imageUrl) {
      // Check if the image URL is a valid Firebase Storage URL
      if (postData.imageUrl.startsWith('https://firebasestorage.googleapis.com/')) {
        await deleteImageFromStorage(postData.imageUrl);
      } else {
        console.warn(`Skipping deletion of invalid image URL: ${postData.imageUrl}`);
      }
    }
    if (postData.videoUrl) {
      // Check if the video URL is a valid Firebase Storage URL
      if (postData.videoUrl.startsWith('https://firebasestorage.googleapis.com/')) {
        await deleteVideoFromStorage(postData.videoUrl);
      } else {
        console.warn(`Skipping deletion of invalid video URL: ${postData.videoUrl}`);
      }
    }

  } catch (error) {
    console.error(`Error marking post as deleted and removing related data:`, error);
    throw new Error(`Error marking post as deleted and removing related data: ${error.message}`);
  }
};

// Function to update a post in the database with enhanced logging
export const updatePostInDB = async (collectionName, postId, updatedData) => {
  try {
    const postIdStr = String(postId);
    const postRef = doc(db, collectionName, postIdStr);
    const postDoc = await getDoc(postRef);

    if (!postDoc.exists()) {
      throw new Error(`Post with ID ${postIdStr} does not exist.`);
    }

    await updateDoc(postRef, updatedData);
  } catch (error) {
    console.error("Error updating post in Firestore", error);
    throw error;
  }
};

// Function to get a user by ID
export const getUserById = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // Instead of throwing an error, log the error and return null
      console.error(`User not found with ID ${userId}`);
      return null;
    }

    return { id: userDoc.id, ...userDoc.data() };
  } catch (error) {
    console.error("Error fetching user by ID from Firestore", error);
    // Instead of throwing an error, log the error and return null
    return null;
  }
};

// Function to update the user's bio in Firestore
export const updateUserBio = async (userId, bio) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, { bio }); // Update the bio field in the user's document
  } catch (error) {
    console.error('Error updating bio:', error);
    throw error; // Re-throw the error for handling elsewhere if needed
  }
};

// Function to update message read status
export const updateMessageReadStatus = async (conversationId, currentUsername) => {
  try {
    const q = query(collection(db, 'messages'), where('conversationId', '==', conversationId), where('receiver', '==', currentUsername), where('read', '==', false));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const messageRef = doc.ref;
      await updateDoc(messageRef, { read: true });
    });
  } catch (error) {
    console.error("Error updating message read status", error);
    throw new Error("Error updating message read status");
  }
};

// Function to save contact submission
export const saveContactSubmission = async (submissionData) => {
  try {
    await saveDataToDB('contactSubmissions', submissionData);
  } catch (error) {
    console.error("Error saving contact submission to Firestore", error);
    throw new Error("Error saving contact submission to Firestore");
  }
};

// Function to get all contact submissions
export const getAllContactSubmissions = async () => {
  try {
    return await getAllDataFromDB('contactSubmissions');
  } catch (error) {
    console.error("Error fetching all contact submissions from Firestore", error);
    throw new Error("Error fetching all contact submissions from Firestore");
  }
};

// Function to save HyroClips contact submissions to Firestore
export const saveClipsContactSubmission = async (submissionData) => {
  try {
    await saveDataToDB('clipsContactSubmissions', submissionData); // Use a separate collection for HyroClips
  } catch (error) {
    console.error("Error saving contact submission to Firestore", error);
    throw new Error("Error saving contact submission to Firestore");
  }
};

// Function to get all HyroClips contact submissions
export const getAllClipsContactSubmissions = async () => {
  try {
    return await getAllDataFromDB('clipsContactSubmissions'); // Fetch from the HyroClips collection
  } catch (error) {
    console.error("Error fetching all HyroClips contact submissions from Firestore", error);
    throw new Error("Error fetching all HyroClips contact submissions from Firestore");
  }
};

// Updated function to get contact list by post ID
export const getContactListByPostId = async (postId) => {
  try {
    const contactListRef = collection(db, 'contactListSubmissions');
    const q = query(contactListRef, where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const contactList = [];
    querySnapshot.forEach((doc) => {
      contactList.push({ id: doc.id, ...doc.data() });
    });
    return contactList;
  } catch (error) {
    console.error("Error fetching contact list:", error);
    throw new Error("Error fetching contact list");
  }
};

// Function to save ContactList submission
export const saveContactListSubmission = async (submissionData) => {
  try {
    const docRef = await addDoc(collection(db, 'contactListSubmissions'), {
      ...submissionData,
      timestamp: submissionData.timestamp || new Date().toISOString(), // Ensure timestamp is stored as ISO string
    });
    return { id: docRef.id, ...submissionData, timestamp: submissionData.timestamp };
  } catch (error) {
    console.error('Error saving ContactList submission to Firestore', error);
    throw new Error('Error saving ContactList submission to Firestore');
  }
};

// Function to get ContactList submissions by post ID
export const getContactListSubmissionsByPostId = async (postId) => {
  try {
    const q = query(collection(db, 'contactListSubmissions'), where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const submissions = [];
    querySnapshot.forEach((doc) => {
      submissions.push({ id: doc.id, ...doc.data() });
    });
    return submissions;
  } catch (error) {
    console.error("Error fetching ContactList submissions for post", error);
    throw new Error("Error fetching ContactList submissions for post");
  }
};

// Function to get all ContactList submissions for a user
export const getContactListSubmissionsByUserId = async (userId) => {
  if (!userId) {
    console.error("Invalid userId provided to getContactListSubmissionsByUserId");
    return {};
  }
  try {
    const userPosts = await getClipsPostsByUserId(userId);
    const postIds = userPosts.map(post => post.id);
    
    const submissions = {};
    for (const postId of postIds) {
      const postSubmissions = await getContactListSubmissionsByPostId(postId);
      // Additional check to ensure the post belongs to the user
      if (postSubmissions.length > 0 && postSubmissions[0].userId === userId) {
        submissions[postId] = postSubmissions;
      }
    }
    
    return submissions;
  } catch (error) {
    console.error("Error fetching ContactList submissions for user", error);
    throw new Error("Error fetching ContactList submissions for user");
  }
};

// Function to mark post as reported
export const markPostAsReported = async (postId, collectionName = 'posts') => {
  try {
    if (typeof postId !== 'string') {
      throw new Error("Invalid postId: expected a string");
    }
    const postRef = doc(db, collectionName, postId);
    const postDoc = await getDoc(postRef);
    if (!postDoc.exists()) {
      console.error(`Post with ID ${postId} does not exist.`);
      return; // Or handle based on your application's needs
    }
    await updateDoc(postRef, { reported: true });
  } catch (error) {
    console.error("Error marking post as reported in Firestore", error);
    throw new Error("Error marking post as reported in Firestore");
  }
};

// Function to get reported posts
export const getReportedPosts = async (collectionName = 'posts') => {
  try {
    const q = query(collection(db, collectionName), where('reported', '==', true));
    const querySnapshot = await getDocs(q);
    const reportedPosts = [];
    querySnapshot.forEach((doc) => {
      reportedPosts.push({ id: doc.id, ...doc.data() });
    });
    return reportedPosts;
  } catch (error) {
    console.error("Error fetching reported posts from Firestore", error);
    throw new Error("Error fetching reported posts from Firestore");
  }
};

// Function to mark post as reviewed
export const markPostAsReviewed = async (postId, collectionName = 'posts') => {
  try {
    await updatePostInDB(collectionName, postId, { reported: false });
  } catch (error) {
    console.error("Error marking post as reviewed in Firestore", error);
    throw new Error("Error marking post as reviewed in Firestore");
  }
};

// Function to update the post verification password
export const updatePostVerificationPassword = async (newPassword) => {
  try {
    const settingsRef = doc(db, 'settings', 'postVerificationPassword');
    await setDoc(settingsRef, { value: newPassword });
  } catch (error) {
    console.error("Error updating post verification password in Firestore", error);
    throw new Error("Error updating post verification password in Firestore");
  }
};

// Function to get the post verification password
export const getPostVerificationPassword = async () => {
  try {
    const settingsRef = doc(db, 'settings', 'postVerificationPassword');
    const settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) {
      return settingsDoc.data().value;
    } else {
      throw new Error("Post verification password not found");
    }
  } catch (error) {
    console.error("Error fetching post verification password from Firestore", error);
    throw new Error("Error fetching post verification password from Firestore");
  }
};

// Function to upload an image to Firebase Storage and get the download URL
export const uploadImageToStorage = async (image, path) => {
  console.log('uploadImageToStorage: Starting upload for path:', path);
  try {
    const imageRef = storageRef(storage, path);
    console.log('uploadImageToStorage: Uploading bytes...');
    await uploadBytes(imageRef, image);
    console.log('uploadImageToStorage: Getting download URL...');
    const downloadURL = await getDownloadURL(imageRef);
    console.log(`uploadImageToStorage: Image uploaded to path: ${path}`);
    console.log('uploadImageToStorage: Download URL:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("uploadImageToStorage: Error uploading image to Firebase Storage", error);
    throw new Error("uploadImageToStorage: Error uploading image to Firebase Storage");
  }
};

// Function to upload a video to Firebase Storage and get the download URL
export const uploadVideoToStorage = async (video, path) => {
  console.log('uploadVideoToStorage: Starting upload for path:', path);
  try {
    const videoRef = storageRef(storage, path);
    console.log('uploadVideoToStorage: Uploading bytes...');
    await uploadBytes(videoRef, video);
    console.log('uploadVideoToStorage: Getting download URL...');
    const downloadURL = await getDownloadURL(videoRef);
    console.log(`uploadVideoToStorage: Video uploaded to path: ${path}`);
    console.log('uploadVideoToStorage: Download URL:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("uploadVideoToStorage: Error uploading video to Firebase Storage", error);
    throw new Error("uploadVideoToStorage: Error uploading video to Firebase Storage");
  }
};

// Function to get posts by a user ID from Firestore
export const getPostsByUserId = async (userId, collectionName = 'posts') => {
  try {
    const q = query(collection(db, collectionName), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      posts.push({ id: doc.id, ...doc.data() });
    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts by user ID", error);
    throw new Error("Error fetching posts by user ID");
  }
};

export const getClipsPostsByUserId = async (userId) => {
  try {
    const q = query(collection(db, 'clipsPosts'), where('userId', '==', userId), orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      posts.push({ id: doc.id, ...doc.data() });
    });
    return posts;
  } catch (error) {
    console.error("Error fetching clips posts by user ID", error);
    throw new Error("Error fetching clips posts by user ID");
  }
};

export const getClipsPostById = async (postId) => {
  try {
    const postRef = doc(db, 'clipsPosts', postId);
    const postDoc = await getDoc(postRef);
    if (postDoc.exists()) {
      return { id: postDoc.id, ...postDoc.data() };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error("Error fetching clips post by ID", error);
    throw new Error("Error fetching clips post by ID");
  }
};

// Function to get a post by its ID from Firestore
export const getPostById = async (postId, collectionName = 'posts') => {
  try {
    // Ensure postId is a string
    const postIdStr = String(postId);
    const postRef = doc(db, collectionName, postIdStr);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      console.error(`No post found with ID ${postIdStr}`);
      return null; // Return null if the post doesn't exist
    }

    return { id: postSnapshot.id, ...postSnapshot.data() };
  } catch (error) {
    console.error("Error fetching post by ID from Firestore", error);
    return null; // Return null in case of error
  }
};

// Function to mark report as reviewed (HyroClips)
export const markReportAsReviewed = async (reportId, collectionName = 'clipsReports') => {
  try {
    const reportRef = doc(db, collectionName, reportId);
    await updateDoc(reportRef, { reviewed: true });
    console.log(`Report with ID ${reportId} marked as reviewed in Firestore (${collectionName})`);
  } catch (error) {
    console.error(`Error marking report as reviewed in Firestore (${collectionName})`, error);
    throw new Error(`Error marking report as reviewed in Firestore (${collectionName})`);
  }
};

// Function to mark report as post deleted (HyroClips)
export const markReportAsDeleted = async (reportId, collectionName = 'clipsReports') => {
  try {
    const reportRef = doc(db, collectionName, reportId);
    await updateDoc(reportRef, { postDeleted: true });
    console.log(`Report with ID ${reportId} marked as post deleted in Firestore (${collectionName})`);
  } catch (error) {
    console.error(`Error marking report as post deleted in Firestore (${collectionName})`, error);
    throw new Error(`Error marking report as post deleted in Firestore (${collectionName})`);
  }
};

// Function to check if a conversation already exists
export const checkConversationExists = async (conversationId) => {
  const q = query(collection(db, 'conversations'), where('id', '==', conversationId));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};

// Function to create a new conversation
export const createConversation = async (conversationId, participants) => {
  try {
    await setDoc(doc(db, 'conversations', conversationId), { participants });
    console.log(`Conversation ${conversationId} created successfully`);
  } catch (error) {
    console.error("Error creating conversation", error);
    throw new Error("Error creating conversation");
  }
};

// Function to get the last submission from a specific collection
export const getLastSubmission = async (collectionName) => {
  try {
    const q = query(collection(db, collectionName), orderBy('timestamp', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data(); // Return the most recent submission data
    }
    return null; // Return null if there are no documents
  } catch (error) {
    console.error("Error getting last submission from Firestore", error);
    throw new Error("Error getting last submission from Firestore");
  }
};

// Function to delete an image from Firebase Storage
export const deleteImageFromStorage = async (path) => {
  const imageRef = storageRef(storage, path);
  try {
    await deleteObject(imageRef);
    console.log(`Image successfully deleted from path: ${path}`);
  } catch (error) {
    // Check if the error code is 'storage/object-not-found'
    if (error.code === 'storage/object-not-found') {
      console.warn(`Image not found in Firebase Storage, continuing deletion process: ${path}`);
    } else {
      // If it's a different error, then throw it
      console.error("Error deleting image from Firebase Storage", error);
      throw new Error("Error deleting image from Firebase Storage");
    }
  }
};

// Function to delete a video from Firebase Storage
export const deleteVideoFromStorage = async (path) => {
  const videoRef = storageRef(storage, path);
  try {
    await deleteObject(videoRef);
    console.log(`Video successfully deleted from path: ${path}`);
  } catch (error) {
    // Check if the error code is 'storage/object-not-found'
    if (error.code === 'storage/object-not-found') {
      console.warn(`Video not found in Firebase Storage, continuing deletion process: ${path}`);
    } else {
      // If it's a different error, then throw it
      console.error("Error deleting video from Firebase Storage", error);
      throw new Error("Error deleting video from Firebase Storage");
    }
  }
};

// Functions to updated and save select fields of ClipsPostForm in Firebase Storage
export const saveUserProfileData = async (userId, profileData) => {
  try {
    const sanitizedProfileData = Object.entries(profileData).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    await setDoc(doc(db, 'userProfiles', userId), sanitizedProfileData, { merge: true });
    console.log('User profile data saved successfully');
  } catch (error) {
    console.error('Error saving user profile data:', error);
    throw error;
  }
};

export const getUserProfileData = async (userId) => {
  try {
    const docRef = doc(db, 'userProfiles', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No user profile found');
      return null;
    }
  } catch (error) {
    console.error('Error getting user profile data:', error);
    throw error;
  }
};

// Function to save a user's feedback selection
export const saveFeedbackSelection = async (postId, userId, option) => {
  try {
    const feedbackSelectionRef = doc(db, 'feedbackSelections', postId, 'userSelections', userId);
    await setDoc(feedbackSelectionRef, { [option]: true }, { merge: true });
  } catch (error) {
    console.error('Error saving feedback selection:', error);
    throw new Error('Error saving feedback selection');
  }
};

// Function to remove a user's feedback selection
export const removeFeedbackSelection = async (postId, userId, option) => {
  try {
    const feedbackSelectionRef = doc(db, 'feedbackSelections', postId, 'userSelections', userId);
    await updateDoc(feedbackSelectionRef, { [option]: deleteField() });
  } catch (error) {
    console.error('Error removing feedback selection:', error);
    throw new Error('Error removing feedback selection');
  }
};

// Function to get the user's feedback selections for a specific post
export const getUserFeedbackSelections = async (postId, userId) => {
  try {
    const feedbackSelectionRef = doc(db, 'feedbackSelections', postId, 'userSelections', userId);
    const feedbackSelectionDoc = await getDoc(feedbackSelectionRef);
    if (feedbackSelectionDoc.exists()) {
      return feedbackSelectionDoc.data();
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error getting user feedback selections:', error);
    throw new Error('Error getting user feedback selections');
  }
};

// Function to update the feedback count for a specific option
export const updateFeedbackCount = async (postId, option, value) => {
  try {
    const feedbackCountRef = doc(db, 'clipsPosts', postId, 'feedbackCounts', 'counts');
    await updateDoc(feedbackCountRef, { [option]: increment(value) });
  } catch (error) {
    console.error('Error updating feedback count:', error);
    throw new Error('Error updating feedback count');
  }
};

// Function to get the feedback counts for a specific post
export const getFeedbackCounts = async (postId) => {
  try {
    const feedbackCountsRef = doc(db, 'clipsPosts', postId, 'feedbackCounts', 'counts');
    const feedbackCountsDoc = await getDoc(feedbackCountsRef);
    if (feedbackCountsDoc.exists()) {
      return feedbackCountsDoc.data();
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error getting feedback counts:', error);
    throw new Error('Error getting feedback counts');
  }
};

// Function to save or update a post summary
export const savePostSummary = async (postId, summary) => {
  try {
    await setDoc(doc(db, 'postSummaries', postId), { summary }, { merge: true });
    console.log(`Summary for post ${postId} saved successfully`);
  } catch (error) {
    console.error("Error saving post summary", error);
    throw new Error("Error saving post summary");
  }
};

// Function to get a post summary
export const getPostSummary = async (postId) => {
  try {
    const summaryDoc = await getDoc(doc(db, 'postSummaries', postId));
    if (summaryDoc.exists()) {
      return summaryDoc.data().summary;
    }
    return null;
  } catch (error) {
    console.error("Error getting post summary", error);
    throw new Error("Error getting post summary");
  }
};

// Function to get post summaries for multiple post IDs
export const getPostSummaries = async (postIds) => {
  try {
    const summaries = {};
    const chunks = [];

    // Split postIds into chunks of 10
    for (let i = 0; i < postIds.length; i += 10) {
      chunks.push(postIds.slice(i, i + 10));
    }

    // For each chunk, perform the query
    for (const chunk of chunks) {
      const summariesRef = collection(db, 'postSummaries');
      const q = query(summariesRef, where(documentId(), 'in', chunk));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        summaries[doc.id] = doc.data().summary;
      });
    }
    return summaries;
  } catch (error) {
    console.error("Error getting post summaries", error);
    throw new Error("Error getting post summaries");
  }
};

// Function to update post summary
export const updatePostSummary = async (postId, newDescription) => {
  try {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    if (!apiKey) {
      console.error('OpenAI API key is not configured.');
      return null;
    }

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: 'You are an assistant that generates concise summaries that closely resemble the original text.'
          },
          {
            role: 'user',
            content: `Summarize the following text in 8-10 words as a complete sentence, capturing the essence of the original text:\n\n${newDescription}`
          }
        ],
        max_tokens: 20,
        temperature: 0.7,
        n: 1,
        stop: ['\n'],
      },
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        }
      }
    );

    const generatedSummary = response.data.choices[0].message.content.trim();
    await setDoc(doc(db, 'postSummaries', postId), { summary: generatedSummary }, { merge: true });
    console.log(`Summary for post ${postId} updated successfully`);
    return generatedSummary;
  } catch (error) {
    console.error("Error updating post summary", error);
    return null;
  }
};

// Function to save or update a bio summary
export const saveBioSummary = async (userName, summary) => {
  try {
    await setDoc(doc(db, 'bioSummaries', userName), { summary }, { merge: true });
    console.log(`Summary for user ${userName} saved successfully`);
  } catch (error) {
    console.error("Error saving bio summary", error);
    throw new Error("Error saving bio summary");
  }
};

// Function to get a bio summary
export const getBioSummary = async (userName) => {
  try {
    const summaryDoc = await getDoc(doc(db, 'bioSummaries', userName));
    if (summaryDoc.exists()) {
      return summaryDoc.data().summary;
    }
    return null;
  } catch (error) {
    console.error("Error getting bio summary", error);
    throw new Error("Error getting bio summary");
  }
};

// Function to get bio summaries for multiple user names
export const getBioSummaries = async (userNames) => {
  try {
    const summaries = {};
    const chunks = [];

    // Split userNames into chunks of 10
    for (let i = 0; i < userNames.length; i += 10) {
      chunks.push(userNames.slice(i, i + 10));
    }

    // For each chunk, perform the query
    for (const chunk of chunks) {
      const summariesRef = collection(db, 'bioSummaries');
      const q = query(summariesRef, where(documentId(), 'in', chunk));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        summaries[doc.id] = doc.data().summary;
      });
    }
    return summaries;
  } catch (error) {
    console.error("Error getting bio summaries", error);
    throw new Error("Error getting bio summaries");
  }
};

// Function to increment view count
export const incrementViewCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          viewCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing view count:', error);
  }
};

// Function to increment link click count
export const incrementLinkClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          linkClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing link click count:', error);
  }
};

// Function to increment impression count
export const incrementImpressionCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          impressionCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing impression count:', error);
  }
};

// Function to increment Instagram click count
export const incrementInstagramClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          instagramClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing Instagram click count:', error);
  }
};

// Function to increment Twitter click count
export const incrementTwitterClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          twitterClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing Twitter click count:', error);
  }
};

// Function to increment TikTok click count
export const incrementTiktokClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          tiktokClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing TikTok click count:', error);
  }
};

// Function to increment YouTube click count
export const incrementYoutubeClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          youtubeClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing YouTube click count:', error);
  }
};

// Function to increment Facebook click count
export const incrementFacebookClickCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          facebookClickCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing Facebook click count:', error);
  }
};

// Function to get analytics data for a post
export const getPostAnalytics = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      const postDoc = await getDoc(postRef);
      if (postDoc.exists()) {
          const data = postDoc.data();
          return {
              viewCount: data.viewCount || 0,
              linkClickCount: data.linkClickCount || 0,
              impressionCount: data.impressionCount || 0,
              instagramClickCount: data.instagramClickCount || 0,
              twitterClickCount: data.twitterClickCount || 0,
              tiktokClickCount: data.tiktokClickCount || 0,
              youtubeClickCount: data.youtubeClickCount || 0,
              facebookClickCount: data.facebookClickCount || 0
          };
      }
      return null;
  } catch (error) {
      console.error('Error getting post analytics:', error);
      return null;
  }
};

// Function to increment Discovery Box impression count
export const incrementDiscoveryBoxImpressionCount = async (postId) => {
  try {
      const postRef = doc(db, 'clipsPosts', postId);
      await updateDoc(postRef, {
          discoveryBoxImpressionCount: increment(1)
      });
  } catch (error) {
      console.error('Error incrementing Discovery Box impression count:', error);
  }
};

export const getTotalDiscoveryBoxImpressions = async () => {
  try {
      const impressionsSnapshot = await getDocs(collection(db, 'discoveryBoxImpressions'));
      let totalImpressions = 0;
      impressionsSnapshot.forEach((doc) => {
          totalImpressions += doc.data().count;
      });
      return totalImpressions;
  } catch (error) {
      console.error('Error getting total Discovery Box impressions:', error);
      return 0;
  }
};

// Function to rank total impressions for Trending component
export const getTrendingPosts = async () => {
  const postsRef = collection(db, 'clipsPosts');
  const q = query(postsRef, orderBy('impressionCount', 'desc'), limit(20));

  try {
    const snapshot = await getDocs(q);
    const trendingPosts = [];

    for (const doc of snapshot.docs) {
      const post = doc.data();
      const summary = await getPostSummary(doc.id); // Fetch the post summary
      trendingPosts.push({
        id: doc.id,
        title: post.title,
        city: post.city || '',     // Add city field
        state: post.state || '',   // Add state field
        summary: summary || post.description.substring(0, 100) + '...', // Use summary if available, else fallback to truncated description
        impressionCount: post.impressionCount
      });
    }

    return trendingPosts;
  } catch (error) {
    console.error('Error fetching trending posts:', error);
    return [];
  }
};

export const fetchOrUpdateRanks = async () => {
  try {
    const settingsRef = doc(db, 'settings', 'trendingRanks');
    const settingsDoc = await getDoc(settingsRef);

    // Check if ranks need updating
    const now = Date.now();
    const lastUpdated = settingsDoc.exists() ? settingsDoc.data().lastUpdated?.toMillis() : 0;

    // Update if over an hour has passed
    if (!lastUpdated || now - lastUpdated > 3600 * 1000) {
      console.log('Updating ranks...');
      const postsRef = collection(db, 'clipsPosts');
      const q = query(postsRef, orderBy('impressionCount', 'desc'), limit(100));
      const snapshot = await getDocs(q);

      const rankedPosts = [];
      const batchUpdates = [];
      snapshot.docs.forEach((postDoc, index) => {
        const postData = postDoc.data();
        rankedPosts.push({ id: postDoc.id, ...postData, rank: index + 1 });

        // Update rank in Firestore
        batchUpdates.push(setDoc(doc(db, 'clipsPosts', postDoc.id), { rank: index + 1 }, { merge: true }));
      });

      // Commit rank updates in batch
      await Promise.all(batchUpdates);

      // Update the settings with the new timestamp
      await setDoc(settingsRef, { lastUpdated: serverTimestamp() });

      return rankedPosts;
    } else {
      console.log('Fetching cached ranks...');
      // Fetch the cached ranks
      const postsRef = collection(db, 'clipsPosts');
      const q = query(postsRef, orderBy('rank', 'asc'), limit(100));
      const snapshot = await getDocs(q);

      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
  } catch (error) {
    console.error('Error fetching or updating ranks:', error);
    throw new Error('Failed to fetch or update ranks.');
  }
};

// New Post Impressions UI functions for Post Viewers
export const updatePostImpressions = async (postId) => {
  try {
    const now = new Date().getTime();
    const lastTracked = localStorage.getItem(`lastImpression_${postId}`);
    
    if (!lastTracked || (now - parseInt(lastTracked)) > 3600000) { // 1 hour in milliseconds
      const impressionRef = doc(db, 'postImpressions', postId);
      await setDoc(impressionRef, { count: increment(1) }, { merge: true });
      localStorage.setItem(`lastImpression_${postId}`, now.toString());
    }
    
    // Always update localStorage count
    const localCount = parseInt(localStorage.getItem(`localImpressionCount_${postId}`) || '0');
    localStorage.setItem(`localImpressionCount_${postId}`, (localCount + 1).toString());
  } catch (error) {
    console.error('Error updating post impressions:', error);
  }
};

export const getPostImpressions = async (postId) => {
  try {
    const impressionRef = doc(db, 'postImpressions', postId);
    const impressionDoc = await getDoc(impressionRef);
    return impressionDoc.exists() ? impressionDoc.data().count : 0;
  } catch (error) {
    console.error('Error getting post impressions:', error);
    return 0;
  }
};

export const getTotalViews = async (postId) => {
  const firestoreCount = await getPostImpressions(postId);
  const localCount = parseInt(localStorage.getItem(`localImpressionCount_${postId}`) || '0');
  return firestoreCount + localCount;
};
