// NewPosts.js

import React, { useContext, useEffect, useMemo, useState } from 'react';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { FaSpinner } from 'react-icons/fa';
import './HomePage.css'; // keep this import
import { Box, Fade, List, ListItem, Paper, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityTracker from './VisibilityTracker';

const NewPosts = () => {
  const { posts, fetchMorePosts, loading, hasMore } = useContext(ClipsPostsContext);
  const navigate = useNavigate();

  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // UPDATED: Use the same container style as in HomePage.js—notice padding is set to 0.
  const postContainerStyle = useMemo(
    () => ({
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto 15px',
      padding: 0,
      boxSizing: 'border-box',
    }),
    []
  );

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Infinite scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 &&
        !loading &&
        hasMore
      ) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, fetchMorePosts]);

  // Sync clickedPosts with localStorage
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // Select random 3 valid posts
  const randomPosts = useMemo(() => {
    const validPosts = posts.filter((post) => !post.deleted && !post.isReported);
    const shuffledPosts = [...validPosts].sort(() => Math.random() - 0.5);
    return shuffledPosts.slice(0, 3);
  }, [posts, randomPostsKey]);

  // Navigate + highlight visited
  const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
  };

  // Refresh the random "Discover Posts"
  const handleRefresh = () => {
    setRandomPostsKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="posts">
      {posts.map((post, index) => (
        <React.Fragment key={post.id}>
          <div className="post-container" style={postContainerStyle}>
            <ClipsPost {...post} />
          </div>

          {/* Insert "Discover Posts" Paper after each block of 3 posts, except the last */}
          {(index + 1) % 3 === 0 && index + 1 < posts.length && (
            <Fade in={true} timeout={800}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 4,
                  mt: 4,
                  width: '100%',
                  maxWidth: '800px',
                  margin: '0 auto',
                  mb: 2,
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 4,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: '700', color: '#1d1d1f', lineHeight: 1.2 }}
                  >
                    Discover Posts
                  </Typography>
                  <IconButton
                    onClick={handleRefresh}
                    sx={{
                      color: '#007aff',
                      padding: '8px',
                      backgroundColor: '#f5f5f7',
                      '&:hover': { backgroundColor: '#e8e8ed' },
                      transition: 'background-color 0.3s ease',
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Box>

                <List sx={{ width: '100%', padding: 0 }}>
                  {randomPosts.map((rPost, index) => (
                    <VisibilityTracker key={rPost.id} postId={rPost.id} isDiscoveryBox={true}>
                      <Fade in={true} timeout={500 + index * 100} unmountOnExit>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '16px 0',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => handlePostClick(rPost.id)}
                              sx={{
                                color: clickedPosts[rPost.id] ? '#551A8B' : '#1a0dab',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                '&:hover': { textDecoration: 'underline' },
                                fontSize: '1.1rem',
                                marginBottom: '4px',
                              }}
                            >
                              {rPost.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{ color: '#86868b', fontSize: '0.9rem' }}
                            >
                              {rPost.city && rPost.state
                                ? `(${rPost.city}, ${rPost.state})`
                                : rPost.city
                                ? `(${rPost.city})`
                                : rPost.state
                                ? `(${rPost.state})`
                                : ''}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#1d1d1f',
                              fontWeight: 400,
                              fontSize: '0.95rem',
                            }}
                          >
                            {rPost.summary || (rPost.description?.length > 100 ? `${rPost.description.substring(0, 100)}...` : rPost.description)}
                          </Typography>
                        </ListItem>
                      </Fade>
                    </VisibilityTracker>
                  ))}
                </List>
              </Paper>
            </Fade>
          )}
        </React.Fragment>
      ))}

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <FaSpinner className="loading-spinner" size={24} spin />
        </div>
      )}
    </div>
  );
};

export default NewPosts;
