// ClipsPostPage.js

import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
  Fade,
  Paper,
  List,
  ListItem
} from '@mui/material';
import { BsMegaphone } from 'react-icons/bs';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  FaRegPaperPlane,
  FaLink,
  FaInstagram,
  FaTwitter,
  FaTiktok,
  FaYoutube,
  FaFacebook
} from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { CiViewList } from 'react-icons/ci';

import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { deletePostFromDB } from '../utils/dbUtilities';
import { timeAgo } from '../utils/timeFormatter';

import ClipsReportForm from './ClipsReportForm';
import ChatWindow from './ChatWindow';
import ContactList from './ContactList';
import SharePost from './SharePost';
import CloseButton from './CloseButton';
import './Post.css';
import { db } from '../utils/firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import Feedback from './Feedback';
import { FeedbackProvider } from '../contexts/FeedbackContext';
import VisibilityTracker from './VisibilityTracker';

import {
  incrementViewCount,
  incrementLinkClickCount,
  incrementInstagramClickCount,
  incrementTwitterClickCount,
  incrementTiktokClickCount,
  incrementYoutubeClickCount,
  incrementFacebookClickCount
} from '../utils/dbUtilities';

////////////////////////////////////////////////////////////////////////////////
// Constants and Helpers
////////////////////////////////////////////////////////////////////////////////

const TWO_MINUTES = 2 * 60 * 1000;

/**
 * Helper to shorten a URL to 25 chars
 */
const shortenUrl = (url) => {
  const maxLength = 25;
  return url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;
};

/**
 * Helper to split text into bullet lines, if you prefer
 * or just returns the original text if short
 */
const splitText = (text) => {
  if (!text) return [];
  // Example split logic:
  return text.split(/(?<=[.?!])\s+(?=[A-Z])/);
};

////////////////////////////////////////////////////////////////////////////////
// Component
////////////////////////////////////////////////////////////////////////////////

const ClipsPostPage = () => {
  ////////////////////////////////////////////////////////////////////////////
  // Context and Router
  ////////////////////////////////////////////////////////////////////////////
  const { posts } = useContext(ClipsPostsContext);
  const { reportPost } = useContext(ClipsReportsContext);
  const { currentUser, isLoggedIn, isAdmin } = useAuth();

  const { id } = useParams();
  const navigate = useNavigate();

  ////////////////////////////////////////////////////////////////////////////
  // Media Queries
  ////////////////////////////////////////////////////////////////////////////
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
  const isSurfacePro7 = useMediaQuery('(min-width: 1368px) and (max-width: 1368px)');
  const isVeryLargeScreen = useMediaQuery('(min-width: 1300px)');

  ////////////////////////////////////////////////////////////////////////////
  // States
  ////////////////////////////////////////////////////////////////////////////
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isProfileImageExpanded, setIsProfileImageExpanded] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [showChatWindow, setShowChatWindow] = useState(false);

  // Track visited styling for post titles
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  // For checking if user is the author
  const [isAuthor, setIsAuthor] = useState(false);

  // Key to refresh random posts
  const [randomPostsKey, setRandomPostsKey] = useState(0);

  ////////////////////////////////////////////////////////////////////////////
  // Effects + Callbacks
  ////////////////////////////////////////////////////////////////////////////

  // Fetch post from context
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) {
      setIsLoading(false);
      return;
    }
    const fetchedPost = posts.find((p) => p.id?.toString() === id);
    setPost(fetchedPost || null);
    setIsLoading(false);
  }, [posts, id]);

  // Mark as deleted or confirm isAuthor
  useEffect(() => {
    if (!isLoading && post) {
      if (post.deleted) {
        setIsDeleted(true);
      } else if (currentUser && currentUser.displayName === post.author) {
        setIsAuthor(true);
      }
    } else if (!isLoading && !post) {
      setIsDeleted(true);
    }
  }, [isLoading, post, currentUser]);

  // On mount, increment post view count once
  useEffect(() => {
    if (post?.id) {
      incrementViewCount(post.id);
    }
  }, [post?.id]);

  // Real-time likes
  useEffect(() => {
    if (!post?.id) return;
    const likesQuery = query(collection(db, 'clipsLikes'), where('postId', '==', post.id));
    const unsubscribe = onSnapshot(likesQuery, (snapshot) => {
      const likes = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLikeCount(likes.length);
      if (currentUser) {
        const isLiked = likes.some((like) => like.userId === currentUser.uid);
        setPost((prev) => (prev ? { ...prev, liked: isLiked } : null));
      }
    });
    return () => unsubscribe();
  }, [post, currentUser]);

  // Poll post data every 2 minutes for analytics
  const fetchPostData = useCallback(async () => {
    if (!post?.id) return;
    try {
      const docRef = doc(db, 'clipsPosts', post.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPost((prev) =>
          prev
            ? {
                ...prev,
                viewCount: data.viewCount || 0,
                linkClickCount: data.linkClickCount || 0,
                impressionCount: data.impressionCount || 0,
                instagramClickCount: data.instagramClickCount || 0,
                twitterClickCount: data.twitterClickCount || 0,
                tiktokClickCount: data.tiktokClickCount || 0,
                youtubeClickCount: data.youtubeClickCount || 0,
                facebookClickCount: data.facebookClickCount || 0
              }
            : prev
        );
      }
    } catch (err) {
      console.error('Error polling post data:', err);
    }
  }, [post?.id]);

  useEffect(() => {
    if (!post?.id) return;
    // initial fetch
    fetchPostData();
    // poll every 2 min
    const intervalId = setInterval(() => {
      fetchPostData();
    }, TWO_MINUTES);
    return () => clearInterval(intervalId);
  }, [post?.id, fetchPostData]);

  // Keep local “clickedPosts” in sync
  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) setClickedPosts(JSON.parse(saved));
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  // Build random posts from existing context
  const randomPosts = useMemo(() => {
    const validPosts = posts.filter((p) => p.id !== id && !p.deleted && !p.isReported);
    const shuffled = [...validPosts].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 3);
  }, [posts, id, randomPostsKey]);

  const handleRefresh = () => {
    setRandomPostsKey((prevKey) => prevKey + 1);
  };

  // Show/hide like count only for author or admin
  const canSeeLikesCount = useCallback(() => {
    return isAuthor || isAdmin;
  }, [isAuthor, isAdmin]);

  ////////////////////////////////////////////////////////////////////////////
  // Early Returns
  ////////////////////////////////////////////////////////////////////////////

  if (isLoading) return null;

  if (isDeleted || !post) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
          ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
          '@media (min-width: 1030px) and (max-width: 1300px)': {
            mt: -5
          }
        }}
        className="post-page-container"
      >
        <Card
          className="post clips-post-page"
          sx={{
            maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
            mb: 2,
            marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
            marginRight: isSurfacePro7 ? 'auto' : '0'
          }}
        >
          <CardContent>
            <Typography variant="h6">This Post No Longer Exists</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (post.isReported) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
          ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' }
        }}
        className="post-page-container"
      >
        <Card
          className="post"
          sx={{
            maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
            mb: 2,
            marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
            marginRight: isSurfacePro7 ? 'auto' : '0'
          }}
        >
          <CardContent>
            <Typography variant="h6">Reported Post Under Review</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  ////////////////////////////////////////////////////////////////////////////
  // Handlers
  ////////////////////////////////////////////////////////////////////////////

  const handlePostClick = (postId) => {
    const updated = { ...clickedPosts, [postId]: true };
    setClickedPosts(updated);
    localStorage.setItem('clickedPosts', JSON.stringify(updated));
    navigate(`/clipsposts/${postId}`);
  };

  const handleLike = async () => {
    if (!isLoggedIn || !currentUser) {
      navigate('/login');
      return;
    }
    if (post.liked) {
      const likeQuery = query(
        collection(db, 'clipsLikes'),
        where('postId', '==', post.id),
        where('userId', '==', currentUser.uid)
      );
      const snapshot = await getDocs(likeQuery);
      const likeDoc = snapshot.docs[0];
      if (likeDoc) {
        await deleteDoc(likeDoc.ref);
      }
    } else {
      await addDoc(collection(db, 'clipsLikes'), {
        postId: post.id,
        userId: currentUser.uid,
        timestamp: new Date()
      });
    }
  };

  const handleRemove = async () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePostFromDB('clipsPosts', id);
        setIsDeleted(true);
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  const handleUsernameClick = () => {
    navigate(`/clipsusersposts/${post.author}`);
  };

  const toggleReportForm = () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowReportForm((prev) => !prev);
  };

  const toggleContactList = () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowContactList((prev) => !prev);
  };

  const closeContactList = () => setShowContactList(false);

  const openChatWindow = () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    setShowChatWindow(true);
  };

  const navigateToCategoryPosts = (category) => navigate(`/categoryposts/${category}`);
  const navigateToCityPosts = (city) => navigate(`/cityposts/${city}`);
  const navigateToStatePosts = (state) => navigate(`/stateposts/${state}`);
  const navigateToCountryPosts = (country) => navigate(`/countryposts/${country}`);
  const navigateToLocationPosts = (location) => navigate(`/locationposts/${location}`);

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Social Link Clicks
  const handleLinkClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementLinkClickCount(post.id);
    }
    openLinkInNewTab(
      post.link.startsWith('http') ? post.link : `https://${post.link}`
    );
  };

  const handleInstagramClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementInstagramClickCount(post.id);
    }
    openLinkInNewTab(`https://instagram.com/${post.instagram.replace('@', '')}`);
  };

  const handleTwitterClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementTwitterClickCount(post.id);
    }
    openLinkInNewTab(`https://twitter.com/${post.twitter.replace('@', '')}`);
  };

  const handleTiktokClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementTiktokClickCount(post.id);
    }
    openLinkInNewTab(`https://tiktok.com/@${post.tiktok.replace('@', '')}`);
  };

  const handleYoutubeClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementYoutubeClickCount(post.id);
    }
    openLinkInNewTab(`https://youtube.com/@${post.youtube.replace('@', '')}`);
  };

  const handleFacebookClick = async (e) => {
    e.stopPropagation();
    if (post?.id) {
      await incrementFacebookClickCount(post.id);
    }
    openLinkInNewTab(`https://www.facebook.com/${post.facebook}`);
  };

  const handleProfileImageClick = () => {
    setIsProfileImageExpanded(true);
  };

  ////////////////////////////////////////////////////////////////////////////
  // Render
  ////////////////////////////////////////////////////////////////////////////

  const postDate = post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp);
  const lastEditedDate = post.lastEdited?.toDate ? post.lastEdited.toDate() : post.lastEdited;

  return (
    <FeedbackProvider>
      <VisibilityTracker postId={id} isDiscoveryBox={false} isPostAuthor={post.author === currentUser?.uid}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            pt: { xs: 10, sm: 12, md: 15, lg: 15, xl: 15 },
            ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
            '@media (min-width: 1030px) and (max-width: 1300px)': {
              mt: -5
            }
          }}
          className="post-page-container"
        >
          <Card
            className="post clips-post-page"
            sx={{
              maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
              mb: 2,
              marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
              marginRight: isSurfacePro7 ? 'auto' : '0',
              paddingBottom: '40px'
            }}
          >
            {/* Cover Image if provided */}
            {post.imageUrl && (
              <CardMedia
                component="img"
                src={post.imageUrl}
                alt={post.title}
              />
            )}

            {/* Card Content */}
            <CardContent
              sx={{
                width: '100%',
                alignItems: 'flex-start',
                position: 'relative',
                paddingTop: '40px'
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  boxSizing: 'border-box'
                }}
              >
                {/* Title and Location */}
                <div style={{ marginTop: '-10px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="clips-post-title"
                    style={{
                      textAlign: 'left',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '5px',
                      width: '100%'
                    }}
                    onClick={() => handlePostClick(post.id)}
                  >
                    <span
                      className="title-link"
                      style={{
                        flex: '1 1 auto',
                        wordBreak: 'break-word',
                        color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab'
                      }}
                    >
                      {post.title}
                    </span>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="clips-post-location"
                    style={{
                      textAlign: 'left',
                      width: '100%',
                      cursor: 'pointer',
                      marginBottom: '15px'
                    }}
                  >
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToCityPosts(post.city);
                      }}
                      className="location-link"
                    >
                      {post.city}
                    </span>
                    {post.state && (
                      <>
                        ,{' '}
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToStatePosts(post.state);
                          }}
                          className="location-link"
                        >
                          {post.state}
                        </span>
                      </>
                    )}
                    {post.country && (
                      <>
                        ,{' '}
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            navigateToCountryPosts(post.country);
                          }}
                          className="location-link"
                        >
                          {post.country}
                        </span>
                      </>
                    )}{' '}
                    (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToLocationPosts(post.location);
                      }}
                      className="location-link"
                    >
                      {post.location}
                    </span>
                    )
                  </Typography>
                </div>

                {/* Description */}
                <Typography
                  variant="body1"
                  color="text.primary"
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    margin: '10px 0 20px 0',
                    fontSize: '15px',
                    lineHeight: 1.4,
                    color: '#000000',
                    fontFamily: '"Roboto", sans-serif',
                    fontWeight: 400
                  }}
                >
                  {splitText(post.description).length > 1 ? (
                    <ul style={{ paddingLeft: '20px', marginTop: '5px' }}>
                      {splitText(post.description).map((sentence, index) => (
                        <li key={index} style={{ marginBottom: '5px' }}>
                          {sentence.trim()}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    post.description
                  )}
                </Typography>

                {/* Timestamps */}
                <Typography
                  variant="body1"
                  color="text.secondary"
                  className="clips-post-author"
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    cursor: 'pointer',
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '13px',
                    marginBottom: lastEditedDate ? '5px' : '15px'
                  }}
                  onClick={handleUsernameClick}
                >
                  Posted {timeAgo(postDate)} by{' '}
                  <span
                    className="username-link"
                    style={{ fontWeight: 'bold', fontFamily: '"Roboto", sans-serif' }}
                  >
                    {post.author}
                  </span>
                </Typography>

                {lastEditedDate && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="last-edited"
                    style={{
                      textAlign: 'left',
                      width: '100%',
                      cursor: 'pointer',
                      fontFamily: '"Roboto", sans-serif',
                      fontSize: '13px',
                      marginBottom: '15px'
                    }}
                  >
                    Last Edited {timeAgo(lastEditedDate)}
                  </Typography>
                )}
              </div>

              {/* 
                Profile Image / Bio Section (Now matches the overlay style from ClipsPost.js)
              */}
              {(post.userName || post.bio || post.profileImageUrl) && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0px',
                    marginTop: '15px',
                    boxSizing: 'border-box'
                  }}
                >
                  {/* Profile Image + Name */}
                  <div
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      backgroundColor: 'white',
                      padding: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      position: 'relative'
                    }}
                  >
                    {post.profileImageUrl && (
                      <div
                        style={{
                          cursor: 'pointer',
                          marginBottom: '3px',
                          width: '150px',
                          height: '150px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          overflow: 'hidden'
                        }}
                        onClick={handleProfileImageClick}
                      >
                        <img
                          src={post.profileImageUrl}
                          alt="Profile"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}
                        />
                      </div>
                    )}
                    {post.userName && (
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginTop: post.profileImageUrl ? '5px' : '3px',
                          marginBottom: '0',
                          width: '100%',
                          lineHeight: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minHeight: '30px'
                        }}
                      >
                        {post.userName}
                      </Typography>
                    )}
                  </div>

                  {/* Bio */}
                  <div
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      backgroundColor: 'white',
                      padding: post.bio ? '5px 5px 0 5px' : '0'
                    }}
                  >
                    {post.bio && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{
                          fontSize: '15px',
                          lineHeight: 1.4,
                          color: '#000000',
                          fontFamily: '"Roboto", sans-serif',
                          fontWeight: 400,
                          marginBottom: '0'
                        }}
                      >
                        {splitText(post.bio).length > 1 ? (
                          <ul style={{ paddingLeft: '20px', margin: '0' }}>
                            {splitText(post.bio).map((sentence, index) => (
                              <li key={index} style={{ marginBottom: '3px' }}>
                                {sentence.trim()}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          post.bio
                        )}
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </CardContent>

            {/* 2-Min Polled Impressions */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mt: 2,
                mb: 2
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: '#000000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '15px',
                  lineHeight: 1.4,
                  fontFamily: '"Roboto", sans-serif',
                  fontWeight: 700
                }}
              >
                <span
                  style={{
                    backgroundColor: '#fff',
                    color: '#000',
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '15px',
                    padding: '3px 8px',
                    border: '2px solid #000',
                    borderRadius: '6px',
                    marginRight: '8px'
                  }}
                >
                  {post.impressionCount?.toLocaleString() || 0}
                </span>
                Total Views
              </Typography>
            </Box>

            {/* Link */}
            {post.link && (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '15px',
                  marginBottom: '10px'
                }}
              >
                <a
                  href={
                    post.link.startsWith('http')
                      ? post.link
                      : `https://${post.link}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLinkClick(e);
                  }}
                  style={{
                    color: '#1a0dab',
                    textDecoration: 'none',
                    wordBreak: 'break-all',
                    display: 'inline-block',
                    fontSize: '16px',
                    fontWeight: 500
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shortenUrl(post.link)}
                </a>
              </div>
            )}

            {/* Actions */}
            <CardActions
              disableSpacing
              style={{ justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={post.liked ? 'Unlike' : 'Like'}>
                  <IconButton onClick={handleLike} sx={{ color: post.liked ? 'red' : 'inherit' }}>
                    {post.liked ? <Favorite /> : <FavoriteBorder />}
                  </IconButton>
                </Tooltip>
                {canSeeLikesCount() && (
                  <span className="votes-count">{likeCount}</span>
                )}
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                {isAuthor ? (
                  <>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => navigate(`/editclipspost/${id}`)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete this post?')) {
                            deletePostFromDB('clipsPosts', id);
                            setIsDeleted(true);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Report">
                      <IconButton onClick={toggleReportForm}>
                        <OutlinedFlagIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Send Message">
                      <IconButton onClick={openChatWindow} style={{ color: 'inherit' }}>
                        <FaRegPaperPlane size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </CardActions>

            {/* Feedback */}
            <div
              style={{
                padding: '0 16px',
                marginTop: '10px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {isLoggedIn ? (
                <>
                  {/* Heading */}
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginTop: '20px',
                      marginBottom: '10px',
                      color: '#000'
                    }}
                  >
                    Give Feedback
                  </Typography>

                  {/* Actual Feedback Bubbles */}
                  <div style={{ marginTop: '10px' }}>
                    <Feedback postId={id} />
                  </div>
                </>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  style={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    padding: '10px 0'
                  }}
                >
                  <span
                    onClick={() => navigate('/login')}
                    style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Login
                  </span>{' '}
                  or{' '}
                  <span
                    onClick={() => navigate('/signup')}
                    style={{ color: '#1a0dab', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Sign Up
                  </span>{' '}
                  to View &amp; Provide Feedback
                </Typography>
              )}
            </div>

            {/* Follow + Contact */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px',
                width: '100%'
              }}
            >
              {(post.instagram ||
                post.twitter ||
                post.tiktok ||
                post.youtube ||
                post.facebook) && (
                <Typography
                  variant="h6"
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    color: '#000'
                  }}
                >
                  Follow the Growth
                </Typography>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '10px'
                }}
              >
                {post.instagram && (
                  <FaInstagram
                    className="social-icon instagram"
                    onClick={handleInstagramClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {post.twitter && (
                  <FaTwitter
                    className="social-icon twitter"
                    onClick={handleTwitterClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {post.tiktok && (
                  <FaTiktok
                    className="social-icon tiktok"
                    onClick={handleTiktokClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {post.youtube && (
                  <FaYoutube
                    className="social-icon youtube"
                    onClick={handleYoutubeClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
                {post.facebook && (
                  <FaFacebook
                    className="social-icon facebook"
                    onClick={handleFacebookClick}
                    style={{ fontSize: '28px', cursor: 'pointer' }}
                  />
                )}
              </div>

              {post.email && (
                <div
                  style={{
                    width: 'fit-content',
                    textAlign: 'center',
                    margin: '0 auto',
                    marginBottom: '10px'
                  }}
                >
                  <a
                    href={`mailto:${post.email}`}
                    style={{
                      color: '#1a0dab',
                      textDecoration: 'none',
                      display: 'inline-block',
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    {post.email}
                  </a>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                    gap: '10px'
                  }}
                >
                  <Tooltip title="Join Contact List">
                    <IconButton
                      onClick={toggleContactList}
                      style={{ padding: '4px', order: 1, color: '#1a0dab' }}
                    >
                      <GoAlert />
                    </IconButton>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    onClick={toggleContactList}
                    style={{
                      color: '#1a0dab',
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontFamily: '"Roboto", sans-serif',
                      order: 2
                    }}
                  >
                    Email Updates Sign Up
                  </Typography>
                </div>

                {isAuthor && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <CiViewList
                      onClick={() => navigate('/view-contact-list')}
                      style={{
                        fontSize: '24px',
                        cursor: 'pointer',
                        color: '#1a0dab'
                      }}
                    />
                    <Typography
                      variant="body2"
                      onClick={() => navigate('/view-contact-list')}
                      style={{
                        color: '#1a0dab',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        fontFamily: '"Roboto", sans-serif'
                      }}
                    >
                      View Email Sign Ups
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            {/* Categories */}
            <div
              className="categories-container"
              style={{
                marginTop: '20px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '10px',
                width: '100%',
                padding: '0 16px'
              }}
            >
              {post.categories &&
                Array.isArray(post.categories) &&
                post.categories.map((category) => (
                  <span
                    key={category}
                    className="category-chip"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigateToCategoryPosts(category);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f0',
                      padding: '5px 12px',
                      borderRadius: '16px',
                      fontSize: '14px',
                      color: '#333',
                      border: '1px solid #ddd',
                      transition: 'background-color 0.2s',
                      display: 'inline-block',
                      margin: '2px',
                      fontFamily: '"Roboto", sans-serif',
                      fontWeight: 600
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                  >
                    {category}
                  </span>
                ))}
            </div>

            {/* The Goal bubbles */}
            {post.goals && Array.isArray(post.goals) && post.goals.length > 0 && (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      color: '#000'
                    }}
                  >
                    The Goal
                  </Typography>
                </div>

                <div
                  className="goals-container"
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '10px',
                    width: '100%',
                    padding: '0 16px'
                  }}
                >
                  {post.goals.map((goal) => (
                    <span
                      key={goal}
                      className="goal-chip"
                      style={{
                        cursor: 'default',
                        backgroundColor: '#e0f4ff',
                        padding: '5px 12px',
                        borderRadius: '16px',
                        fontSize: '14px',
                        color: '#333',
                        border: '1px solid #99e0ff',
                        transition: 'background-color 0.2s',
                        display: 'inline-block',
                        margin: '2px',
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 600
                      }}
                    >
                      {goal}
                    </span>
                  ))}
                </div>
              </>
            )}

            {/* Report Form */}
            {showReportForm && (
              <ClipsReportForm
                postId={id}
                onSubmit={(reason) => {
                  reportPost(id, reason);
                  setShowReportForm(false);
                }}
                onClose={() => setShowReportForm(false)}
              />
            )}

            {/* Contact List */}
            {showContactList && (
              <ContactList postId={id} onClose={closeContactList} />
            )}
          </Card>

          {/* Random Posts Section */}
          {randomPosts.length > 0 && (
            <Fade in={true} timeout={800}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 4,
                  mt: 4,
                  width: '100%',
                  maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                  mb: 2,
                  marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                  marginRight: isSurfacePro7 ? 'auto' : '0',
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 4
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
                    Discover Posts
                  </Typography>
                  <IconButton
                    onClick={handleRefresh}
                    sx={{
                      color: '#007aff',
                      backgroundColor: '#f5f5f7',
                      '&:hover': { backgroundColor: '#e8e8ed' },
                      transition: 'background-color 0.3s ease'
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Box>

                <List sx={{ width: '100%', padding: 0 }}>
                  {randomPosts.map((randomPost, index) => (
                    <VisibilityTracker
                      key={randomPost.id}
                      postId={randomPost.id}
                      isDiscoveryBox={true}
                    >
                      <Fade in={true} timeout={500 + index * 100}>
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '16px 0',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: 'transparent' }
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                            <Typography
                              variant="body1"
                              component="span"
                              onClick={() => handlePostClick(randomPost.id)}
                              sx={{
                                color: clickedPosts[randomPost.id] ? '#551A8B' : '#1a0dab',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                '&:hover': { textDecoration: 'underline' },
                                fontSize: '1.1rem',
                                marginBottom: '4px'
                              }}
                            >
                              {randomPost.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{ color: '#86868b', fontSize: '0.9rem' }}
                            >
                              {randomPost.city && randomPost.state
                                ? `(${randomPost.city}, ${randomPost.state})`
                                : randomPost.city
                                ? `(${randomPost.city})`
                                : randomPost.state
                                ? `(${randomPost.state})`
                                : ''}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: '#1d1d1f',
                              fontWeight: 400,
                              fontSize: '0.95rem'
                            }}
                          >
                            {randomPost.summary ||
                              (randomPost.description?.length > 100
                                ? `${randomPost.description.substring(0, 100)}...`
                                : randomPost.description)}
                          </Typography>
                        </ListItem>
                      </Fade>
                    </VisibilityTracker>
                  ))}
                </List>
              </Paper>
            </Fade>
          )}

          <CloseButton />
        </Box>
      </VisibilityTracker>

      {/* 
        Full-Screen Overlay for Profile Image 
        (matches the approach from ClipsPost.js)
      */}
      {isProfileImageExpanded && post.profileImageUrl && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999
          }}
          onClick={() => setIsProfileImageExpanded(false)}
        >
          <div
            style={{
              position: 'relative',
              maxWidth: '80%',
              maxHeight: '80%'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <CloseButton
              onClick={() => setIsProfileImageExpanded(false)}
              style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                zIndex: 1001,
                fontSize: '24px'
              }}
            />
            <img
              src={post.profileImageUrl}
              alt="Profile Expanded"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '8px'
              }}
            />
          </div>
        </div>
      )}
    </FeedbackProvider>
  );
};

export default ClipsPostPage;

